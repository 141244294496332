import React from 'react';
import ConfirmModal from 'components/ConfirmModal';
import { Typography } from 'ui-kit';

export interface IConfirmModalDeductOrderProduct {
  isOpen: boolean;
  onConfirm: () => void;
  onCancel: () => void;
}
const ConfirmModalDeductOrderProduct = ({
  isOpen,
  onConfirm,
  onCancel,
}: IConfirmModalDeductOrderProduct) => (
  <ConfirmModal
    visible={isOpen}
    onConfirm={onConfirm}
    onCancel={onCancel}
    confirmText="Yes"
    cancelText="No"
    confirmBtnType="primary"
    destroyOnClose
  >
    <Typography variant="h4" style={{ marginBottom: '16px' }}>
      Remove product
    </Typography>
    <Typography variant="body2">
      Are you sure you want to remove this product from the order? This action cannot be undone.
    </Typography>
  </ConfirmModal>
);

export default ConfirmModalDeductOrderProduct;
