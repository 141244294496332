import React from 'react';
import { Checkbox } from 'antd';
import { Link } from 'ui-kit';
import './styles.scss';
import { AGREEMENT_ID } from '../CheckoutForm/constants';

export interface IAgreement {
  onChange: (checked: boolean) => void;
}

const Agreement = ({ onChange }: IAgreement) => {
  return (
    <div className="CheckoutForm_customerFormAgreementWrapper" id={AGREEMENT_ID}>
      <div className="CheckoutForm_customerFormAgreement">
        <div className="CheckoutForm_checkoutFormCheckboxWrapper">
          <Checkbox onChange={(e) => onChange(e.target.checked)} />
        </div>
        <div>
          I agree to The Host Co{' '}
          <Link target="_blank" href="https://www.thehost.co/terms-conditions">
            terms of service
          </Link>{' '}
          and{' '}
          <Link target="_blank" href="https://www.thehost.co/privacy-policy">
            privacy policy.
          </Link>{' '}
        </div>
      </div>
      <p>
        I understand that local vendor items may include a Host Co concierge fee in total price.
        Transaction will appear on your card as The Host Co or The Host Company HC Inc., and may
        include the terms Web or Software Service.
      </p>
    </div>
  );
};

export default Agreement;
