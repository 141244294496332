import React, { useState, useRef, useEffect, useMemo, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import useQuery from 'hooks/useQuery';
import { useLoadingBackdrop } from 'hooks/useLoadingBackdrop';
import _ from 'lodash';

import { message, Alert, Row, Divider } from 'antd';
import { Button, Spin, TextField, Typography } from 'ui-kit';

import UnauthLayout from 'components/UnauthLayout';
import PurchaseDetailsTable from 'components/PurchaseDetails/PurchaseDetailsTable';
import {
  getPurchaseByActorUUID,
  approveOnHoldPurchase,
  cancelOnHoldPurchase,
  IPurchaseDetail,
  ActorType,
} from 'api/purchase_sdk';
import { PurchaseStatus } from 'api/types/order';

import { stringConvertToBoolean } from 'utils/utilsV2';

import 'components/LoginPage/LoginPage.scss';
import './PurchaseRequestPage.scss';

export default function PurchaseRequestPage() {
  const { actorType, purchaseUUID } = useParams<{ actorType: ActorType; purchaseUUID: string }>();
  const params = useQuery();
  const [error, setError] = useState('');
  const [purchase, setPurchase] = useState<IPurchaseDetail>();
  const [loading, setLoading] = useState<boolean>(true);
  const [note, setNote] = useState<string>('');
  const alertRef = useRef(null);
  const [showLoading, hideLoading] = useLoadingBackdrop();

  useEffect(() => {
    getPurchaseByActorUUID({ actorType, uuid: purchaseUUID })
      .then((res) => {
        setPurchase(res);
      })
      .catch((err) => {
        setError(err.message);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const showPrices = useMemo(
    () => stringConvertToBoolean({ value: _.get(params, 'showPrices', 'false') }),
    [params],
  );

  const handleCancelOrder = useCallback(() => {
    showLoading();
    cancelOnHoldPurchase({ actorType, uuid: purchaseUUID, approvalNote: note })
      .then(() => {
        const action = actorType === ActorType.GUEST ? 'cancel' : 'deny';
        window.location.href = `/${actorType}/${action}`;
        hideLoading();
      })
      .catch((err) => {
        message.error(err.message);
        hideLoading();
      });
  }, [actorType, purchaseUUID, note, showLoading, hideLoading]);

  const handleApproveOrder = useCallback(() => {
    showLoading();
    approveOnHoldPurchase({ actorType, uuid: purchaseUUID, approvalNote: note })
      .then(() => {
        window.location.href = `/${actorType}/approve`;
        hideLoading();
      })
      .catch((err) => {
        message.error(err.message);
        hideLoading();
      });
  }, [actorType, purchaseUUID, note, showLoading, hideLoading]);

  const canApprove = useMemo(
    () => actorType === ActorType.OWNER || actorType === ActorType.MERCHANT,
    [actorType],
  );

  const purchaseIsOnHold = useMemo(() => purchase?.status === PurchaseStatus.HOLD, [purchase]);

  return (
    <div className="OrderRequest">
      <UnauthLayout>
        <Spin spinning={loading}>
          {!purchase && error && (
            <Row justify="center" ref={alertRef} tabIndex={-1}>
              <Alert className="OrderRequest_alert" type="error" message={error} />
            </Row>
          )}
          {purchase && !purchaseIsOnHold && (
            <Row justify="center" ref={alertRef} tabIndex={-1}>
              <Alert
                className="OrderRequest_alert"
                type="error"
                message="This order is no longer on hold, so you won't be able to make changes to it."
              />
            </Row>
          )}
          {purchase && (
            <div>
              <Typography className="title" variant="h6">
                Order #{purchase.order_number}
              </Typography>
              <Divider />
              <PurchaseDetailsTable
                purchase={purchase}
                actorType={actorType}
                showPrices={showPrices}
              />
              {canApprove && purchaseIsOnHold && (
                <div className="OrderRequest_note">
                  <TextField
                    fullWidth
                    rows={4}
                    multiline
                    inputProps={{ maxLength: 10000 }}
                    label="Note"
                    placeholder="Extra instructions, scheduling, etc."
                    value={note}
                    onChange={(e) => setNote(e.target.value)}
                  />
                </div>
              )}
              <Row justify="space-around" className="button-row">
                <Button
                  color="error"
                  variant="contained"
                  type="button"
                  className="action-buttons"
                  onClick={handleCancelOrder}
                  disabled={!purchaseIsOnHold}
                >
                  {canApprove ? 'Deny' : 'Cancel'}
                </Button>
                {canApprove && (
                  <Button
                    variant="contained"
                    type="button"
                    className="action-buttons"
                    onClick={handleApproveOrder}
                    disabled={!purchaseIsOnHold}
                  >
                    Approve
                  </Button>
                )}
              </Row>
            </div>
          )}
        </Spin>
      </UnauthLayout>
    </div>
  );
}
