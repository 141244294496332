import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import Svg from 'react-inlinesvg';
import { accountIcon, rocketIcon } from './icons';
import { LogOut } from 'react-feather';
import { useAuth } from 'contexts/auth/auth';
import { useHistory } from 'react-router-dom';
import MenuItems from './MenuItems';
import { Badge, Spinner } from 'ui-kit';
import { useOwnerDetails } from 'api/owners';
import { PaymentPlan } from 'api/types/owner';

import { EProfileTab } from './constants';
import { reverse } from 'utils/urls';
import { URLS } from 'components/Routes/urls';

export default function AccountMenu({ onSignOut, isCoHost }) {
  const history = useHistory();
  const accountLink = useMemo(
    () =>
      isCoHost
        ? reverse({ url: URLS.ACCOUNT, params: { tab: EProfileTab.STORE_DETAILS } })
        : reverse({ url: URLS.ACCOUNT, params: { tab: EProfileTab.PERSONAL_DETAILS } }),
    [isCoHost],
  );

  const getItems = () => [
    {
      key: 'account',
      label: <LabelMenu />,
      type: 'submenu',
      items: [
        {
          key: 'account',
          label: 'Account',
          icon: <Svg src={accountIcon} />,
          onClick: () => history.push(accountLink),
        },
        {
          key: 'subscription',
          label: 'Subscription',
          icon: <Svg src={rocketIcon} />,
          onClick: () => history.push('/pricing'),
        },
        {
          key: 'divider-1',
          type: 'divider',
        },
        {
          key: 'sign-out',
          label: 'Sign out',
          icon: <LogOut />,
          onClick: onSignOut,
        },
      ],
    },
  ];
  return <MenuItems items={getItems()} />;
}

AccountMenu.propTypes = {
  onSignOut: PropTypes.func,
  isCoHost: PropTypes.bool,
};

const LabelMenu = () => {
  const { id: ownerId } = useAuth();
  const { data, isLoading } = useOwnerDetails(ownerId);
  const { plan, actor } = data || {};
  const { firstName } = actor || {};
  if (isLoading) {
    return <Spinner />;
  }

  return (
    <div className="TopNav_accountMenu">
      <div className="TopNav_accountMenu-name-block">
        <span>Hi, {firstName}</span>
        {plan !== PaymentPlan.Basic && (
          <Badge
            badgeContent={PLAN_LABELS[plan]}
            classNames="TopNav_accountMenu-name-block_plan"
            color="secondary"
          />
        )}
      </div>
      <div className="TopNav_accountMenu-avatar">
        <span>{firstName?.charAt(0).toUpperCase()}</span>
      </div>
    </div>
  );
};

const PLAN_LABELS = {
  BASIC: 'Basic',
  PRO: 'Pro',
  PRO_PLUS: 'Pro Plus',
  ENTERPRISE: 'Enterprise',
};
