import React, { useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import _ from 'lodash';
import dayjs from 'dayjs';

import { useOrderProductList } from 'api/order_product_sdk';

import { EDateFilter } from '../OrdersList/constants';

import Filters from 'components/Filters';
import OverviewLayout from 'components/OverviewLayout/OverviewLayout';
import OrderProductsTable from 'components/OrderProducts/OrderProductsTable';
import OrderProductsExportButton from 'components/OrderProducts/OrderProductsExportButton';

import { getLocalDatetime } from 'utils/datetime';

import './styles.scss';

// Default values from to day to one month ahead
const currentDate = dayjs().toDate();
const nextMonthDate = dayjs().add(1, 'month').toDate();

const ServicesCalendar = () => {
  const [selectedStore, setSelectedStore] = useState(undefined);
  const [selectedStatus, setSelectedStatus] = useState(undefined);
  const [selectedDateFilter, setSelectedDateFilter] = useState(EDateFilter.DATE_OF_SERVICE);
  const [startDate, setStartDate] = useState<Date | undefined>(currentDate);
  const [endDate, setEndDate] = useState<Date | undefined>(nextMonthDate);

  const {
    data: orderProductsData,
    currentPage,
    count,
    setPage,
    setCurrentPage,
    setOffset,
    mutate: refetchOrders,
    isLoading,
  } = useOrderProductList({
    storeId: selectedStore,
    status: selectedStatus,
    dateFilter: selectedDateFilter,
    startDate: startDate ? getLocalDatetime(startDate, 'YYYY-MM-DD') : undefined,
    endDate: endDate ? getLocalDatetime(endDate, 'YYYY-MM-DD') : undefined,
  });

  const orderProducts = useMemo(() => {
    return orderProductsData?.results || [];
  }, [orderProductsData]);

  return (
    <OverviewLayout>
      <Helmet>
        <title>Services Calendar | The Host Co</title>
      </Helmet>
      <p className="page-title">Services Calendar</p>

      <Filters
        selectedStore={selectedStore}
        setSelectedStore={setSelectedStore}
        selectedStatus={selectedStatus}
        setSelectedStatus={setSelectedStatus}
        selectedDateFilter={selectedDateFilter}
        setSelectedDateFilter={setSelectedDateFilter}
        disableSelectedDateFilter={true}
        startDate={startDate}
        setStartDate={setStartDate}
        endDate={endDate}
        setEndDate={setEndDate}
        setCurrentPage={setCurrentPage}
        setOffset={setOffset}
      />
      <OrderProductsTable
        products={orderProducts}
        loading={isLoading}
        currentPage={currentPage}
        count={count}
        setPage={setPage}
        onRefundCompleted={refetchOrders}
      />
      <div style={{ float: 'right' }}>
        <OrderProductsExportButton
          selectedFilters={{
            selectedStore,
            selectedStatus,
            startDate,
            endDate,
            dateFilter: selectedDateFilter,
          }}
          disabled={orderProducts.length === 0}
        />
      </div>
    </OverviewLayout>
  );
};

export default ServicesCalendar;
