import React, { useCallback, useEffect, useMemo, useState } from 'react';
import _ from 'lodash';
import { useHistory, useParams } from 'react-router-dom';

import { useStoreDetails } from 'api/store';
import { retryPayment } from 'api/order_sdk';
import { useGuestOrderDetails } from 'api/guest_sdk';

import Stack from 'ui-kit/Stack';
import { Button, Card, Spin, Typography } from 'ui-kit';
import OrderProduct from 'components/Store/NewCheckout/OrderProduct';
import StoreLayoutHeader from 'components/StoreLayout/Header';
import Layout from 'components/StoreLayout';
import Footer from 'components/Layout/Footer';
import SuccessPayment from 'components/Store/NewCheckout/SuccessPayment';
import OrderSummary from 'components/Store/NewCheckout/OrderSummary';
import { URLS } from 'components/Routes/urls';

import { usePollingCheckoutDetails } from './utils';
import { reverse } from 'utils/urls';

import './styles.scss';
import { message } from 'antd';

const CheckoutDetailsPage = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const history = useHistory();

  const params = useParams<{
    storeId: string;
    orderId: string;
  }>();

  const storeId = useMemo(() => Number(params.storeId), [params.storeId]);
  const orderId = useMemo(() => Number(params.orderId), [params.orderId]);

  const {
    data: orderDetails,
    isLoading: isOrderDetailsLoading,
    mutate: updateGuestOrderdetails,
  } = useGuestOrderDetails({
    orderId,
  });

  const { data: storeData, isLoading: isStoreLoading } = useStoreDetails(storeId);

  const enterpriseInfo = useMemo(() => storeData?.enterpriseInfo, [storeData]);
  const isEnterprise = useMemo(() => enterpriseInfo?.isEnterprise, [enterpriseInfo]);
  const isLoading = useMemo(
    () => isOrderDetailsLoading || isStoreLoading,
    [isOrderDetailsLoading, isStoreLoading],
  );
  const companyName = useMemo(() => enterpriseInfo?.companyName, [enterpriseInfo]);
  const logo = useMemo(() => enterpriseInfo?.logo, [enterpriseInfo]);

  const { isTaskReady } = usePollingCheckoutDetails(orderId);

  useEffect(() => {
    if (isTaskReady) {
      updateGuestOrderdetails();
    }
  }, [isTaskReady]);

  const handleRetryPayment = useCallback(() => {
    setIsSubmitting(true);
    retryPayment({ orderId })
      .then(() => {
        setIsSubmitting(false);
        history.push(
          reverse({
            url: URLS.CART_DETAILS,
            params: { storeId },
          }),
        );
      })
      .catch((error) => {
        setIsSubmitting(false);
        message.error(error.message);
      });
  }, [orderId, storeId]);

  if (!orderDetails) {
    return null;
  }

  if (!orderDetails.is_ready) {
    return (
      <Spin spinning={true} className="StoreCheckout_loading" tip="Processing payment...">
        <></>
      </Spin>
    );
  }

  // When all payments are done and there is no error
  if (orderDetails.is_ready && !orderDetails.has_error) {
    return <SuccessPayment storeId={storeId} />;
  }

  if (orderDetails.is_ready && orderDetails.has_error) {
    return (
      <>
        <StoreLayoutHeader
          companyName={companyName}
          logo={logo}
          isEnterprise={isEnterprise}
          isLoading={isLoading}
        ></StoreLayoutHeader>
        <Layout.Content className="StoreCheckout_layout">
          <div className="StoreCheckout_content">
            <Typography className="StoreCheckout_content__pageHeader" variant="h4">
              Payment Declined
            </Typography>
            <Typography className="StoreCheckout_content__pageText" variant="body1">
              Oh no! The transaction failed, please update your billing details to proceed.
            </Typography>
            <Button
              className="StoreCheckout_content__retryButton"
              variant="contained"
              onClick={handleRetryPayment}
              disabled={isSubmitting}
            >
              Update details
            </Button>

            <div style={{ marginTop: 20, marginBottom: 20 }}>
              <Card style={{ padding: '20px' }}>
                <Typography variant="h6" style={{ marginBottom: '8px', fontWeight: 'bold' }}>
                  Guest details
                </Typography>
                <Typography variant="body1">Order #: {orderDetails.order_number}</Typography>
                <Typography variant="body1">Store: {orderDetails.store_name}</Typography>
                <Typography variant="body1">Name: {orderDetails.guest_name}</Typography>
                <Typography variant="body1">Email: {orderDetails.guest_email}</Typography>
                <Typography variant="body1">Phone: {orderDetails.guest_phone}</Typography>
              </Card>
            </div>

            <OrderSummary order={orderDetails} />
            <div style={{ marginTop: 20, marginBottom: 20 }}>
              <Card style={{ padding: '20px' }}>
                {orderDetails?.order_products?.length > 0 ? (
                  <Stack flexDirection="column" gap="8px">
                    {orderDetails?.order_products?.map((orderProduct) => (
                      <OrderProduct
                        key={orderProduct.id}
                        orderProduct={orderProduct}
                        currency={orderDetails.currency}
                        showCountControls={false}
                        showPurchaseStatus={true}
                      />
                    ))}
                  </Stack>
                ) : (
                  <div>Nothing has been added to your cart yet!</div>
                )}
              </Card>
            </div>
            <Button variant="contained" to={`/store/${storeId}/`} style={{ marginTop: 20 }}>
              Back to store
            </Button>
          </div>
        </Layout.Content>
        <Footer isEnterprise={enterpriseInfo?.isEnabled} about={enterpriseInfo?.about} />
      </>
    );
  }
};

export default CheckoutDetailsPage;
