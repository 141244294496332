import React, { useCallback } from 'react';
import { ChevronDown as ChevronDownIcon } from 'react-feather';
import dayjs from 'dayjs';

import { Button, Dropdown, Menu } from 'ui-kit';

import { PurchaseStatus } from 'api/types/order';
import { exportOrderOrderProducts, exportOrders } from 'api/order_sdk';

import { getLocalDatetime } from 'utils/datetime';
import { downloadListOfListsAsCSV } from 'utils/csv';
import { EDateFilter } from 'pages/OrdersList/constants';

export interface IOrdersExportButton {
  selectedFilters: {
    selectedStore?: number;
    selectedStatus?: PurchaseStatus;
    startDate?: Date;
    endDate?: Date;
    dateFilter?: EDateFilter;
  };
  disabled: boolean;
}

const OrdersExportButton = ({ selectedFilters, disabled }: IOrdersExportButton) => {
  const handleExportOrders = useCallback(
    () =>
      exportOrders({
        storeId: selectedFilters.selectedStore,
        status: selectedFilters.selectedStatus,
        startDate: selectedFilters.startDate
          ? getLocalDatetime(selectedFilters.startDate, 'YYYY-MM-DD')
          : undefined,
        endDate: selectedFilters.endDate
          ? getLocalDatetime(selectedFilters.endDate, 'YYYY-MM-DD')
          : undefined,
        dateFilter: selectedFilters.dateFilter,
      }).then((res) => {
        const dataForCSV = [
          ['Guest name', 'Date of Purchase', 'Amount', 'Order Number', 'Store Name'],
          ...res.map((order) => [
            order.guest.guest_name,
            dayjs(order.date_created).format('DD MMMM YYYY'),
            order.grand_total,
            order.order_number,
            order.store.store_name,
          ]),
        ];

        downloadListOfListsAsCSV({
          data: dataForCSV,
          filename: 'order_sales.csv',
        });
      }),
    [selectedFilters],
  );

  const handleExportProducts = useCallback(
    () =>
      exportOrderOrderProducts({
        storeId: selectedFilters.selectedStore,
        status: selectedFilters.selectedStatus,
        startDate: selectedFilters.startDate
          ? getLocalDatetime(selectedFilters.startDate, 'YYYY-MM-DD')
          : undefined,
        endDate: selectedFilters.endDate
          ? getLocalDatetime(selectedFilters.endDate, 'YYYY-MM-DD')
          : undefined,
        dateFilter: selectedFilters.dateFilter,
      }).then((res) => {
        const dataForCSV = [
          [
            'Guest Name',
            'Product Name',
            'Date created',
            'Date of service',
            'Amount',
            'Your Profit (Your profit may not be exact due to process timing)',
            'Order Number',
            'Store Name',
          ],
          ...res.map((product) => [
            product.guest.guest_name,
            product.product_name,
            dayjs(product.date_created).format('DD MMMM YYYY'),
            dayjs(product.delivery_date).format('DD MMMM YYYY'),
            product.guest_amount,
            product.owner_profit,
            product.order_number,
            product.store_name,
          ]),
        ];

        downloadListOfListsAsCSV({
          data: dataForCSV,
          filename: 'product_sales.csv',
        });
      }),
    [selectedFilters],
  );

  return (
    <Dropdown
      placement="bottomRight"
      trigger={['click']}
      overlay={
        <Menu>
          <Menu.Item onClick={handleExportOrders}>Orders (CSV)</Menu.Item>
          <Menu.Item onClick={handleExportProducts}>Products (CSV)</Menu.Item>
        </Menu>
      }
      disabled={disabled}
    >
      <Button variant="contained" endIcon={<ChevronDownIcon />}>
        Export
      </Button>
    </Dropdown>
  );
};

export default OrdersExportButton;
