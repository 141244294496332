import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import _ from 'lodash';

import { useStoreDetails } from 'api/store';
import Layout from 'components/StoreLayout';
import BackToStore from 'components/Store/NewCheckout/BackToStore';

import './styles.scss';
import PayNowButton from 'components/Store/NewCheckout/PayNowButton';
import { Card, Spin } from 'ui-kit';
import OrderSummary from 'components/Store/NewCheckout/OrderSummary';
import OrderProduct from 'components/Store/NewCheckout/OrderProduct';
import { useGuestCartDetail } from 'api/guest_sdk';
import StoreLayoutHeader from 'components/StoreLayout/Header';
import Stack from 'ui-kit/Stack';
import Footer from 'components/Layout/Footer';

const CartDetailsPage = () => {
  const params = useParams<{ storeId?: string; orderId?: string; customerId?: string }>();
  const storeId = Number(params.storeId);

  const {
    data: order,
    isLoading: isCartLoading,
    mutate: mutateGuestOrder,
  } = useGuestCartDetail({
    storeId,
  });

  const { data: storeData, isLoading: isStoreLoading } = useStoreDetails(storeId);

  const enterpriseInfo = useMemo(() => storeData?.enterpriseInfo, [storeData]);
  const isEnterprise = useMemo(() => enterpriseInfo?.isEnterprise, [enterpriseInfo]);
  const isLoading = useMemo(() => isCartLoading || isStoreLoading, [isCartLoading, isStoreLoading]);
  const companyName = useMemo(() => enterpriseInfo?.companyName, [enterpriseInfo]);
  const logo = useMemo(() => enterpriseInfo?.logo, [enterpriseInfo]);

  return (
    <Spin spinning={isLoading}>
      <StoreLayoutHeader
        companyName={companyName}
        logo={logo}
        isEnterprise={isEnterprise}
        isLoading={isLoading}
      ></StoreLayoutHeader>
      <Layout.Content className="StoreCheckout_layout">
        <div className="StoreCheckout_content">
          <div style={{ marginBottom: 20 }}>
            <BackToStore storeId={storeId} />
          </div>
          {order && <OrderSummary order={order} />}
          <div style={{ marginTop: 20, marginBottom: 20 }}>
            <Card style={{ padding: '20px' }}>
              {order?.order_products?.length > 0 ? (
                <Stack flexDirection="column" gap="8px">
                  {order?.order_products?.map((orderProduct) => (
                    <OrderProduct
                      key={orderProduct.id}
                      orderProduct={orderProduct}
                      currency={order.currency}
                      onAddItem={mutateGuestOrder}
                      onDeductItem={mutateGuestOrder}
                    />
                  ))}
                </Stack>
              ) : (
                <div>Nothing has been added to your cart yet!</div>
              )}
            </Card>
          </div>
        </div>
      </Layout.Content>
      <Footer isEnterprise={enterpriseInfo?.isEnabled} about={enterpriseInfo?.about} />
      <div className="StoreCheckout-fix-actions">
        <div className="StoreCheckout-fix-actions-content">
          <PayNowButton storeId={storeId} />
        </div>
      </div>
    </Spin>
  );
};

export default CartDetailsPage;
