import React from 'react';
import clsx from 'clsx';
import { InputHelperText, InputLabel } from 'ui-kit/Input';
import { InputProps } from 'ui-kit/Input/InputNew';
import './FormControl.scss';

export interface FormControlProps extends Pick<InputProps, 'error'> {
  children: React.ReactNode;
  required?: boolean;
  label?: React.ReactNode;
  helperText?: React.ReactNode;
  errorMessage?: React.ReactNode;
  fullWidth?: boolean;
  disabled?: boolean;
  className?: string;
  showHelperIcon?: boolean;
}

const BASE_CLASS = 'ui-kit-form-control';

const FormControl = React.forwardRef<HTMLDivElement, FormControlProps>(
  (
    {
      label,
      error,
      errorMessage,
      helperText,
      required,
      children,
      fullWidth,
      disabled = false,
      className,
      showHelperIcon,
    },
    ref,
  ) => {
    return (
      <div
        ref={ref}
        className={clsx(
          BASE_CLASS,
          {
            [`${BASE_CLASS}-error`]: !disabled && error,
            [`${BASE_CLASS}-fullWidth`]: fullWidth,
          },
          className,
        )}
      >
        {label && <InputLabel label={label} required={required} />}
        {children}
        {!disabled && errorMessage && (
          <InputHelperText type="error" showHelperIcon={showHelperIcon}>
            {errorMessage}
          </InputHelperText>
        )}
        {helperText && <InputHelperText>{helperText}</InputHelperText>}
      </div>
    );
  },
);

export default FormControl;
