import React, { useCallback, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { Col, Row, message } from 'antd';
import { CheckCircleOutlined, CloseCircleOutlined, DeleteOutlined } from '@ant-design/icons';
import { Edit3 as EditIcon } from 'react-feather';

import { Radio, RadioGroup, Button, Typography, InputHelperText } from 'ui-kit';
import FormControl from 'ui-kit/FormControl';
import ProductDelegatesCreateModal, { IProductDelegate } from '../ProductDelegatesCreateModal';
import _ from 'lodash';
import { useAuth } from 'contexts/auth/auth';
import { useOwnerDetails } from 'api/owners';
import { PaymentPlan } from 'api/types/owner';
import ConfirmModal from 'components/ConfirmModal';
import clsx from 'clsx';
import { validateAndFormatPhoneNumber } from 'components/Form/InputPhoneMask/utils';

interface IDelegatesField {
  value: Array<IProductDelegate>;
  name: string;
  onChange: (value: Array<IProductDelegate>) => void;
  error?: boolean;
  errorMessage?: string;
}

const DelegatesField = ({ value, name, onChange, error, errorMessage }: IDelegatesField) => {
  const [isSmsNotificationDisabled, setSmsNotificationDisabled] = useState(false);
  const [isDelegateModalOpen, setDelegateModalOpen] = useState(false);
  const [currentDelegates, setCurrentDelegates] = useState(value);
  const [isConfirmDeleteModalVisible, setConfirmDeleteModalVisible] = useState(false);
  const [selectedContactForEdit, setSelectedContactForEdit] = useState<IProductDelegate | null>(
    null,
  );
  const [selectedContactForDelete, setSelectedContactForDelete] = useState<IProductDelegate | null>(
    null,
  );

  const { id: ownerId } = useAuth();
  const { data } = useOwnerDetails(ownerId);

  const { setValue, watch } = useFormContext();
  const notifyDelegates = watch('notifyDelegates');

  useEffect(() => {
    setSmsNotificationDisabled(data.plan === PaymentPlan.Basic);
  }, [data]);

  const handleChange = (e) => {
    setValue('notifyDelegates', e.target.value);
    setValue(name, [], { shouldValidate: true, shouldDirty: true });
  };

  const handleDelegateModalCancel = () => {
    setDelegateModalOpen(false);
    setSelectedContactForEdit(null);
  };

  const handleAddDelegate = ({ data }) => {
    if (
      !_.isUndefined(
        currentDelegates.find(
          (delegate) =>
            delegate.email === data.email &&
            ((_.isNull(delegate.id) && !_.isNil(data.uuid) && data.uuid !== delegate.uuid) ||
              (!_.isNull(delegate.id) && data.id !== delegate.id)),
        ),
      )
    ) {
      message.error('That email has already been added.');
      return;
    }

    if (!_.isNull(data.phoneNumber) && !data.isRequestSmsEnabled && !data.isSalesSmsEnabled) {
      message.error('Select the text info you would like your contact to receive.');
      return;
    }

    if (_.isNull(data.phoneNumber) && (data.isRequestSmsEnabled || data.isSalesSmsEnabled)) {
      message.error('Phone number is required to receive text info.');
      return;
    }

    let newDelegates = [];
    const updatingDelegate = currentDelegates.find(
      (delegate) => (!_.isNull(data.id) && delegate.id === data.id) || delegate.uuid === data.uuid,
    );
    if (!_.isNil(data.id) && !_.isNil(updatingDelegate)) {
      // Update existing delegate
      newDelegates = currentDelegates.map((delegate) =>
        delegate.id === data.id ? data : delegate,
      );
    } else if (_.isNil(data.id) && !_.isNil(updatingDelegate)) {
      // Update newly added delegate
      newDelegates = currentDelegates.map((delegate) =>
        delegate.uuid === data.uuid ? data : delegate,
      );
    } else {
      // Add new delegate
      newDelegates = [...currentDelegates, data];
    }

    setCurrentDelegates(newDelegates);
    onChange(newDelegates);

    setSelectedContactForEdit(null);
    setDelegateModalOpen(false);
  };

  const addDelegate = () => {
    if (currentDelegates.length > 10) {
      message.error('You have met the limit of 10 emails for notifications.');
    } else {
      setDelegateModalOpen(true);
    }
  };

  const handleEditDelegateClick = useCallback(({ delegate }) => {
    setSelectedContactForEdit(delegate);
    setDelegateModalOpen(true);
  }, []);

  const handleDeleteDelegateClick = useCallback(({ delegate }) => {
    setSelectedContactForDelete(delegate);
    setConfirmDeleteModalVisible(true);
  }, []);

  const handleDelegateDelete = useCallback(() => {
    if (_.isNil(selectedContactForDelete)) {
      return;
    }

    setConfirmDeleteModalVisible(false);

    const newDelegates = currentDelegates.filter(
      (delegate) =>
        delegate.id !== selectedContactForDelete.id ||
        delegate.uuid !== selectedContactForDelete.uuid,
    );

    setCurrentDelegates(newDelegates);
    onChange(newDelegates);
    setSelectedContactForDelete(null);
  }, [currentDelegates, selectedContactForDelete]);

  const handleCancelDeleting = useCallback(() => {
    setConfirmDeleteModalVisible(false);
    setSelectedContactForDelete(null);
  }, []);

  return (
    <>
      <Row gutter={[0, 24]}>
        <Col xs={24}>
          <FormControl
            label="Notify someone else via text and email"
            helperText="This includes notifications for request and approve, sales and reminders."
          >
            <RadioGroup value={notifyDelegates} onChange={handleChange}>
              <div>
                <Radio value={true}>Yes</Radio>
                <Radio value={false}>No</Radio>
              </div>
            </RadioGroup>
          </FormControl>
        </Col>
        {notifyDelegates && (
          <>
            <Col xs={21} offset={1} className="DelegatesList">
              {currentDelegates.length > 0 && (
                <>
                  <p className="DelegatesList__title">Contacts</p>
                  {currentDelegates.map((delegate) => (
                    <div key={delegate.id} className="DelegatesList__item">
                      <div className="DelegatesList__item__info">
                        <div className="DelegatesList__item__line">
                          <Typography variant="caption" className="DelegateEmail">
                            {delegate.email}
                          </Typography>
                        </div>
                        {!_.isEmpty(delegate.phoneNumber) && (
                          <div className="DelegatesList__item__line">
                            <Typography variant="caption" className="DelegatePhoneNumber">
                              {validateAndFormatPhoneNumber({
                                phoneNumber: delegate.phoneNumber,
                              }) || delegate.phoneNumber}
                            </Typography>
                            <Typography variant="caption" className="DelegateNotification">
                              {delegate.isRequestSmsEnabled ? (
                                <CheckCircleOutlined
                                  style={{
                                    color: 'green',
                                    fontSize: '14px',
                                  }}
                                />
                              ) : (
                                <CloseCircleOutlined
                                  style={{
                                    color: 'red',
                                    fontSize: '14px',
                                  }}
                                />
                              )}{' '}
                              Request and Approve
                            </Typography>
                            <Typography variant="caption" className="DelegateNotification">
                              {delegate.isSalesSmsEnabled ? (
                                <CheckCircleOutlined
                                  style={{
                                    color: 'green',
                                    fontSize: '14px',
                                  }}
                                />
                              ) : (
                                <CloseCircleOutlined
                                  style={{
                                    color: 'red',
                                    fontSize: '14px',
                                  }}
                                />
                              )}{' '}
                              Sales
                            </Typography>
                          </div>
                        )}
                      </div>
                      <Typography variant="caption" className="DelegateNotification">
                        {delegate.isShowPrice ? (
                          <CheckCircleOutlined
                            style={{
                              color: 'green',
                              fontSize: '14px',
                            }}
                          />
                        ) : (
                          <CloseCircleOutlined
                            style={{
                              color: 'red',
                              fontSize: '14px',
                            }}
                          />
                        )}{' '}
                        Show prices
                      </Typography>
                      <div>
                        <Button
                          size="small"
                          variant="text"
                          onClick={() => handleEditDelegateClick({ delegate })}
                        >
                          <EditIcon />
                        </Button>
                        <Button
                          size="small"
                          variant="text"
                          onClick={() => handleDeleteDelegateClick({ delegate })}
                        >
                          <DeleteOutlined />
                        </Button>
                      </div>
                    </div>
                  ))}
                </>
              )}
              <Button
                variant="outlined"
                size="small"
                className={clsx('DelegatesList__addButton', currentDelegates.length > 0 && 'mt-8')}
                onClick={addDelegate}
              >
                Add contact
              </Button>

              <ProductDelegatesCreateModal
                initialData={selectedContactForEdit}
                isOpen={isDelegateModalOpen}
                onConfirm={handleAddDelegate}
                onCancel={handleDelegateModalCancel}
                disabledSms={isSmsNotificationDisabled}
              />
            </Col>
            <Col>
              {error && (
                <InputHelperText type="error" showHelperIcon={true}>
                  {errorMessage}
                </InputHelperText>
              )}
            </Col>
          </>
        )}
      </Row>
      <ConfirmModal
        visible={isConfirmDeleteModalVisible}
        confirmText="Confirm"
        cancelText="Cancel"
        onCancel={handleCancelDeleting}
        onConfirm={handleDelegateDelete}
        destroyOnClose
      >
        <Typography variant="h4">Are you sure you want to delete this contact?</Typography>
      </ConfirmModal>
    </>
  );
};

export default DelegatesField;
