import React from 'react';
import PropTypes from 'prop-types';
import { Layout } from 'antd';
import { classNames } from 'utils';
import Content from './Content';
import './StoreLayout.scss';

export default function StoreLayout({ className, children, ...rest }) {
  return (
    <Layout className={classNames(className, 'StoreLayout')} {...rest}>
      {children}
    </Layout>
  );
}

StoreLayout.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
};

for (const key of Object.keys(Layout)) {
  StoreLayout[key] = Layout[key];
}

StoreLayout.Content = Content;
