import React, { useState, useEffect, useMemo } from 'react';
import _omit from 'lodash/omit';
import PropTypes from 'prop-types';
import { Menu } from 'antd';
import { useRouteMatch } from 'react-router';

const DEFAULT_INITIAL_ITEM = {
  dashboard: 'store',
};
export default function MenuItems({ mode = 'horizontal', items, initialActiveTab, reset }) {
  const { url = '' } = useRouteMatch();
  const [activeItem, setActiveItem] = useState(null);
  const [openKeys, setOpenKeys] = useState([]);
  const [initialActiveItem] = useMemo(() => {
    return url
      .split('/')
      .filter((segment) => segment.match(/^[a-z-]+$/))
      .slice(-1);
  }, [url]);

  const filterSubmenuIcons = (items) => {
    return items.map((item) => _omit(item, ['icon']));
  };

  const parsedItems = useMemo(() => {
    return items
      .filter(({ type }) => (mode === 'horizontal' ? type !== 'divider' : true))
      .map(({ icon, ...rest }) => (mode === 'horizontal' ? rest : { icon, ...rest }));
  }, [mode]);

  const { initialKey, initalKeys } = useMemo(() => {
    const initalKeys = [];
    let initialKey = DEFAULT_INITIAL_ITEM[initialActiveTab];
    const findKey = (items) => {
      for (const item of items) {
        if (item.key === initialActiveItem) {
          initialKey = item.key;
          return true;
        }
        if (item?.items) {
          const finded = findKey(item.items);
          if (finded) {
            initalKeys.unshift(item.key);
            return true;
          }
        }
      }
    };
    findKey(items);
    return { initialKey, initalKeys };
  }, [initialActiveTab]);

  const renderIcon = (icon) => {
    if (!icon) {
      return null;
    }
    return React.cloneElement(icon, { width: 18, height: 18 });
  };

  const renderItems = (items) => {
    return items.map(({ key, type, label, items = [], icon = null, hidden = false, ...rest }) => {
      if (type === 'submenu') {
        return (
          <Menu.SubMenu key={key} title={label} icon={renderIcon(icon)}>
            {renderItems(mode !== 'horizontal' ? filterSubmenuIcons(items) : items)}
          </Menu.SubMenu>
        );
      }
      if (type === 'divider') {
        return <Menu.Divider key={key} />;
      }
      if (hidden) {
        return null;
      }
      return (
        <Menu.Item key={key} icon={renderIcon(icon)} {...rest}>
          {label}
        </Menu.Item>
      );
    });
  };

  useEffect(() => {
    setActiveItem(initialKey);
    if (mode !== 'horizontal') {
      setOpenKeys(initalKeys);
    }
  }, [reset]);

  return (
    <Menu
      mode={mode}
      selectedKeys={[activeItem]}
      openKeys={openKeys}
      onClick={() => {
        // if (!item.props?.isNotRoute) {
        //   setActiveItem(key);
        // }
        if (mode === 'horizontal') {
          setOpenKeys([]);
        }
      }}
      triggerSubMenuAction="click"
      onOpenChange={setOpenKeys}
      getPopupContainer={() => {
        const appElement = document.getElementsByClassName('TopNav')[0];
        return appElement;
      }}
    >
      {renderItems(parsedItems)}
    </Menu>
  );
}

const item = {
  key: PropTypes.string,
  label: PropTypes.node,
  type: PropTypes.oneOf(['submenu', 'group', 'divider']),
  icon: PropTypes.node,
  onClick: PropTypes.func,
  hidden: PropTypes.bool,
};

MenuItems.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      ...item,
      items: PropTypes.arrayOf(PropTypes.shape(item)),
    }),
  ),
  reset: PropTypes.bool,
  initialActiveTab: PropTypes.oneOf(['dashboard', 'overview']),
  mode: PropTypes.oneOf(['inline', 'horizontal']),
};
