import clsx from 'clsx';
import Logo from 'components/Brand/Logo';
import TextLogo from 'components/Brand/TextLogo';
import { useScrollDirection } from 'hooks/useScrollDirection';
import React, { useEffect, useRef } from 'react';
import { Spinner } from 'ui-kit';

import './StoreLayoutHeader.scss';

export interface IStoreLayoutHeader {
  className?: string;
  children?: React.ReactNode;
  isCartOpen?: boolean;
  isEnterprise: boolean;
  companyName: string;
  logo: string;
  isLoading: boolean;
}

const StoreLayoutHeader = ({
  className,
  children,
  isCartOpen = false,
  isEnterprise,
  companyName,
  logo,
  isLoading,
}: IStoreLayoutHeader) => {
  const headerRef = useRef(null);
  const scrollDirection = useScrollDirection({ threshold: 150 });

  useEffect(() => {
    const element = document.getElementsByClassName('Store-tabs-container')?.[0];
    if (scrollDirection === 'up' || isCartOpen) {
      element?.classList.add('translate');
    } else {
      element?.classList.remove('translate');
    }
  }, [scrollDirection, isCartOpen]);

  return (
    <header
      ref={headerRef}
      className={clsx(className, 'StoreLayoutHeader', {
        fixed: scrollDirection === 'up' || isCartOpen,
      })}
    >
      <div className="StoreLayoutHeader-content">
        {isLoading ? (
          <Spinner />
        ) : (
          <div className="StoreLayoutHeader_logoBlock">
            <Logo logo={isEnterprise && logo ? logo : undefined} className="" />
            <TextLogo
              className="StoreLayoutHeader_textLogo"
              logo={isEnterprise && companyName ? companyName : undefined}
            />
          </div>
        )}

        {children}
      </div>
    </header>
  );
};

export default StoreLayoutHeader;
