import React, { useState, useCallback, useEffect } from 'react';
import { Upload as AntUpload } from 'antd';
import Svg from 'react-inlinesvg';
import ImgCrop from 'antd-img-crop';
import { getBase64, getBase64FromPath } from 'utils';
import { InputHelperText, InputLabel } from 'ui-kit/Input';
import './UploadImage.scss';
import { uploadImageIcon } from './icons';

import 'antd/es/modal/style';
import 'antd/es/slider/style';
import Spinner from 'ui-kit/Spinner';

interface Props {
  label?: React.ReactNode;
  required?: boolean;
  previewUrl?: string;
  onChange: (value: string) => void;
  value: string;
  helperText?: React.ReactNode;
}
const BASE_CLASS = 'ui-kit-image-upload';

const UploadImage = React.forwardRef(
  ({ onChange, value, label, required, helperText, previewUrl }: Props, ref) => {
    const [errorMessage, setErrorMessage] = useState('');
    const [loading, setLoading] = useState(true);

    const validationBeforeUpload = (file) => {
      const isValidType = file.type === 'image/jpeg' || file.type === 'image/png';
      if (!isValidType) {
        setErrorMessage('You can only upload JPG/PNG file!');
      }
      const isValidSize = file.size / 1024 / 1024 < 7;
      if (!isValidSize) {
        setErrorMessage('Image must be smaller than 7MB!');
      }
      return isValidType && isValidSize;
    };

    const onUpload = (info) => {
      const { file } = info;

      if (file.status === 'done') {
        getBase64(file.originFileObj).then((value) => {
          onChange(value);
          if (errorMessage) setErrorMessage('');
        });
      }
    };

    useEffect(() => {
      if (previewUrl) {
        getBase64FromPath(previewUrl).then((fileUri) => {
          onChange(fileUri);
          setLoading(false);
        });
      } else {
        setLoading(false);
      }
    }, [previewUrl]);

    const renderContent = useCallback((value, loading) => {
      if (loading) {
        return <Spinner size="large" />;
      }

      if (!loading && value) {
        return <img src={value} alt="avatar" className={`${BASE_CLASS}-zone-preview`} />;
      }

      return <Svg src={uploadImageIcon} />;
    }, []);

    return (
      <div className={BASE_CLASS}>
        {label && <InputLabel label={label} required={required} />}
        <div>
          <ImgCrop
            rotate
            modalTitle="Crop Image"
            modalMaskTransitionName="none" // crop area gets set to 0 on some browsers if the cropper loads before the modal
            modalTransitionName="none" // removing transitions lets the modal load first all the time
          >
            <AntUpload
              ref={ref}
              accept=".jpeg,.jpg,.png"
              multiple={false}
              maxCount={1}
              beforeUpload={validationBeforeUpload}
              customRequest={({ onSuccess }) => {
                setTimeout(() => {
                  onSuccess('ok', null);
                }, 0);
              }}
              onChange={onUpload}
              showUploadList={false}
              className={`${BASE_CLASS}-zone`}
            >
              {renderContent(value, loading)}
            </AntUpload>
          </ImgCrop>
        </div>
        {errorMessage && <InputHelperText type="error">{errorMessage}</InputHelperText>}
        {helperText && <InputHelperText>{helperText}</InputHelperText>}
      </div>
    );
  },
);

export default UploadImage;
