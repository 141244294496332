import { PurchaseStatus, PurchaseStatusMap } from 'api/types/order';

export const STATUSES_OPTIONS = [
  {
    label: PurchaseStatusMap[PurchaseStatus.COMPLETED],
    value: PurchaseStatus.COMPLETED,
  },
  {
    label: PurchaseStatusMap[PurchaseStatus.HOLD],
    value: PurchaseStatus.HOLD,
  },
  {
    label: PurchaseStatusMap[PurchaseStatus.CANCEL],
    value: PurchaseStatus.CANCEL,
  },
  {
    label: PurchaseStatusMap[PurchaseStatus.REFUNDED],
    value: PurchaseStatus.REFUNDED,
  },
];

export enum EDateFilter {
  DATE_OF_SERVICE = 'date_of_service',
  DATE_CREATED = 'date_created',
}

export const DATE_FILTER_OPTIONS = [
  {
    label: 'Date of Purchase',
    value: EDateFilter.DATE_CREATED,
  },
  {
    label: 'Date of Service',
    value: EDateFilter.DATE_OF_SERVICE,
  },
];
