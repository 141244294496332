import React from 'react';
import { ProductCategory, ProductSubcategory } from 'api/types/product';
import { formatCurrency } from 'utils';
import * as images from './images';
import { Currency } from 'api/types/store';

export interface SuggestedProduct {
  id: number;
  name: string;
  price: string;
  isGuestPrice?: boolean;
  preorderBy?: number;
  requiresApproval?: boolean;
  description: string;
  preview: string;
  instructions: string;
  quantity?: number;
  hasQuantity?: boolean;
  image: string;
  category: ProductCategory;
  subcategory: ProductSubcategory;
}

export const DEFAULT_PRODUCT_DESCRIPTION = `Add all the details here!
Note:
\u2022 If you do not list a cancellation policy with a time frame, your guest will be entitled to a full refund if service is cancelled prior to fulfillment.
\u2022 Your own vendors must have a cancellation and insurance policy.
\u2022 The Host Co is not responsible or liable for services you list individually.
`;

export const PRODUCT_DESCRIPTIONS = {
  midStayClean: `Want an extra clean during your stay? We'll make your bed, put out fresh towels and straighten up your room.  
  Note:
  \u2022 If you do not list a cancellation policy with a time frame, your guest will be entitled to a full refund if service is cancelled prior to fulfillment.
  \u2022 Your own vendors must have a cancellation and insurance policy.
  \u2022 This is an owner-supplied service so please only offer things you can personally provide to guests when purchased.
  `,
  poolHeatingPerDay: `
  Want a heated pool? This is for one day of pool heating, so please increase the quantity for the number of days you're staying. Enjoy!
  Note:
  \u2022 This is an owner-supplied service so please only offer things you can personally provide to guests when purchased.
  `,
  hotTubHeating: `
  Want to use the hot tub? This is for one day of hot tub use, so please increase the quantity for the number of days you're staying. Must include cancellation policy or your guests will be entitled to a full refund upon cancellation.
  Note:
  \u2022 This is an owner-supplied service so please only offer things you can personally provide to guests when purchased.
  `,
  firewood: `Don't drive into town. We have bundles of firewood here.
  Note:
  \u2022 This is an owner-supplied service so please only offer things you can personally provide to guests when purchased.
  `,
  romancePackage: `
  Having a romantic weekend away or an anniversary?
  Our romance package includes a bouquet of flowers, a box of chocolates and rose petals on the bed when you walk in.
  Perfect for a romantic weekend. Must cancel 72 hours in advance for a full refund. 
  NOTE:  
  \u2022 This is an owner-supplied service so please only offer things you can personally provide to guests when purchased.
  `,
  parkingPass: `Use our parking so you don't have to park on the street. Must purchase one per day of your stay. Cancellation policy: Must cancel 5 days in advance for a full refund.
  Note:
  \u2022 This is an owner-supplied service so please only offer things you can personally provide to guests when purchased.
  `,
  packNPlayRental: `
  Rent our pack n' play with washable bedding and pillows. Price is per stay. Must cancel prior to arrival for full refund.
  Note:
  \u2022 This is an owner-supplied service so please only offer things you can personally provide to guests when purchased.
  `,
  bikeRental: `Rent our bikes during your stay. Rental price is per stay for one bicycle. If your rental requires a waiver, we recommend hyperlinking to that waiver here and putting this item on request and approve.
  Note:
  \u2022 This is an owner-supplied service so please only offer things you can personally provide to guests when purchased.
  `,
  laundryRoom: `Use of our laundry room including detergent and dryer sheets.
  Note:
  \u2022 This is an owner-supplied service so please only offer things you can personally provide to guests when purchased.
  `,
  toothbrushAndPaste: `One tube of toothpaste and one toothbrush.
  Note:
  \u2022 This is an owner-supplied service so please only offer things you can personally provide to guests when purchased.
  `,
  brokenWineGlass: `Break a wine glass?  That's ok, you can pay for it here.
  Note:
  \u2022 This is an owner-supplied service so please only offer things you can personally provide to guests when purchased.
  `,
  breakfast: `We serve breakfast with advanced notice. Breakfast includes (INSERT MENU HERE). Must cancel 48 hours in advance for a full refund.
  Note:
  \u2022 This is an owner-supplied service so please only offer things you can personally provide to guests when purchased.
  `,
  extraCoffee: `We provide one morning's worth of coffee. Want extra for your stay? We'll provide an entire bag of coffee and feel free to take home the rest!
  Note:
  \u2022 This is an owner-supplied service so please only offer things you can personally provide to guests when purchased.
  `,
  extraLinens: `Extra sheets, blankets and pillows for the hide-a-bed and extra towels as well.
  Note:
  \u2022 This is an owner-supplied service so please only offer things you can personally provide to guests when purchased.
  `,
  luggageStorage: `Drop off your luggage 4 hours in advance or leave your luggage behind for an additional 4 hours.
  Note:
  \u2022 This is an owner-supplied service so please only offer things you can personally provide to guests when purchased.
  `,
  extraGuest: `Got an extra guest? You can request to pay for your extra guest here. If you have more than one extra guest, be sure to buy multiples!
  Note:
  \u2022 This product is not approved until the guest receives the "Request Approved" email from The Host Co.
  \u2022 Once approved, the guest must cancel with more than 12 hours advance notice for reimbursement.
  \u2022 This is an owner-supplied service so please only offer things you can personally provide to guests when purchased.
  `,
  earlyCheckIn: `Want to check-in early? We'll let you check-in two hours before the scheduled check-in time! 
  Note:
  \u2022 This product is not approved until the guest receives the "Request Approved" email from The Host Co. 
  \u2022 Once approved, the guest must cancel with more than 12 hours advance notice for reimbursement.
  \u2022 This is an owner-supplied service so please only offer things you can personally provide to guests when purchased.
  `,
  petFee: `Want to bring your pet along? You can pay your pet fee here! This applies to up to 2 dogs under (x) pounds only.  
  Note:
  \u2022 This product is not approved until the guest receives the "Request Approved" email from The Host Co.
  \u2022 Once approved, the guest must cancel with more than 12 hours advance notice for reimbursement.
  \u2022 This is an owner-supplied service so please only offer things you can personally provide to guests when purchased.
  `,
  lateCheckOut: `Need some time to sleep in? Go for it! With late check-out we'll give you two more hours from the scheduled checkout time before you have to go!
  Note:
  \u2022 This product is not approved until the guest receives the "Request Approved" email from The Host Co.
  \u2022 Once approved, the guest must cancel with more than 12 hours advance notice for reimbursement.
  \u2022 This is an owner-supplied service so please only offer things you can personally provide to guests when purchased.
  `,
  earPlugs: `One pair of foam earplugs.
  Note:
  \u2022 This is an owner-supplied service so please only offer things you can personally provide to guests when purchased.
  `,
  gratuity: `Love your stay? Leave a tip! Your cleaner will receive all proceeds and get an alert at the time of tipping transaction. Thank you!`,
  tipTheCleaner: `Want to tip the cleaner extra? Send them a tip here!`,
  returnPostage: `Want an item mailed back to you?  You can pay for the postage and handling fees here.  Please don't send until amount has been agreed upon with host.`,
  eventFee: `If you've worked out an event fee or filming fee with your host, you can send those funds here.  Please don't send until amount has been agreed upon with host.`,
  damages: `Break something?  Accidents happen.  This is a quick and easy way to send the funds to your host without having to involve the bookings platform. Please don't send until amount has been agreed upon with host.`,
  testPurchase: `See what your guest sees with a zero dollar test purchase. This includes request and approve, a calendar for date selection, and email receipts to both you and your (test) guest. Don't forget to set this tile to "inactive" after testing.`,
};

export const suggestedProductsData: SuggestedProduct[] = [
  {
    id: 21,
    name: 'Tip the Cleaner',
    price: '0',
    isGuestPrice: true,
    preorderBy: 0,
    quantity: 0,
    hasQuantity: false,
    description: PRODUCT_DESCRIPTIONS.tipTheCleaner,
    preview: 'Leave a tip!',
    instructions: '',
    image: images.tipping,
    category: ProductCategory.IH,
    subcategory: ProductSubcategory.SomethingElse,
  },
  {
    id: 22,
    name: 'Return Postage',
    price: '0',
    isGuestPrice: true,
    preorderBy: 0,
    quantity: 0,
    hasQuantity: false,
    description: PRODUCT_DESCRIPTIONS.returnPostage,
    preview: 'Mail an item back to a guest.',
    instructions: '',
    image: images.postage,
    category: ProductCategory.IH,
    subcategory: ProductSubcategory.SomethingElse,
  },
  {
    id: 24,
    name: 'Damages',
    price: '0',
    isGuestPrice: true,
    preorderBy: 0,
    quantity: 0,
    hasQuantity: false,
    description: PRODUCT_DESCRIPTIONS.damages,
    preview: 'Guests can pay for damages here.',
    instructions: '',
    image: images.damages,
    category: ProductCategory.IH,
    subcategory: ProductSubcategory.SomethingElse,
  },
  {
    id: 23,
    name: 'Event/Filming Fee',
    price: '0',
    isGuestPrice: true,
    preorderBy: 0,
    quantity: 0,
    hasQuantity: false,
    description: PRODUCT_DESCRIPTIONS.eventFee,
    preview: 'Add-ons for events and filming.',
    instructions: '',
    image: images.eventFee,
    category: ProductCategory.IH,
    subcategory: ProductSubcategory.AddOns,
  },

  {
    id: 25,
    name: 'Test Purchase',
    price: '0',
    isGuestPrice: false,
    preorderBy: 0,
    quantity: 0,
    hasQuantity: false,
    requiresApproval: true,
    description: PRODUCT_DESCRIPTIONS.testPurchase,
    preview: 'See how your store works with a $0 sale.',
    instructions: '',
    image: images.testPurchase,
    category: ProductCategory.PO,
    subcategory: ProductSubcategory.SomethingElse,
  },
  {
    id: 1,
    name: 'Mid-stay Clean',
    price: '100',
    preorderBy: 2,
    description: PRODUCT_DESCRIPTIONS.midStayClean,
    preview: 'Additional Clean',
    instructions: '',
    image: images.midweekCleaning,
    category: ProductCategory.PO,
    subcategory: ProductSubcategory.GuestServices,
  },
  {
    id: 2,
    name: 'Pool Heating Per Day',
    price: '75',
    preorderBy: 2,
    description: PRODUCT_DESCRIPTIONS.poolHeatingPerDay,
    preview: 'Per day or per stay, allow your guests to pay for pool heating here',
    instructions: '',
    image: images.poolHeating,
    category: ProductCategory.PO,
    subcategory: ProductSubcategory.GuestServices,
  },
  {
    id: 3,
    name: 'Use of Hot Tub/ Hot Tub Heating',
    price: '30',
    preorderBy: 2,
    description: PRODUCT_DESCRIPTIONS.hotTubHeating,
    preview: 'Charge separately for hot tub heating or hot tub use',
    instructions: '',
    image: images.hotTubHeating,
    category: ProductCategory.PO,
    subcategory: ProductSubcategory.GuestServices,
  },
  {
    id: 4,
    name: 'Firewood',
    price: '20',
    preorderBy: 2,
    description: PRODUCT_DESCRIPTIONS.firewood,
    preview: 'One bundle of firewood',
    instructions: '',
    image: images.firewood,
    category: ProductCategory.PO,
    subcategory: ProductSubcategory.GoodsAndEssentials,
  },
  {
    id: 5,
    name: 'Romance Package',
    price: '125',
    preorderBy: 2,
    description: PRODUCT_DESCRIPTIONS.romancePackage,
    preview: 'Create a romantic package for guests',
    instructions: '',
    image: images.romancePackage,
    category: ProductCategory.PO,
    subcategory: ProductSubcategory.GoodsAndEssentials,
  },
  {
    id: 6,
    name: 'Parking Pass',
    price: '25',
    preorderBy: 2,
    description: PRODUCT_DESCRIPTIONS.parkingPass,
    preview: 'Charge per parking space, per day',
    instructions: '',
    image: images.parkingPass,
    category: ProductCategory.PO,
    subcategory: ProductSubcategory.Rentals,
  },
  {
    id: 7,
    name: 'Pack N Play Rental',
    price: '40',
    preorderBy: 2,
    description: PRODUCT_DESCRIPTIONS.packNPlayRental,
    preview: "Rent a pack n' play with washable bedding",
    instructions: '',
    image: images.packNPlayRental,
    category: ProductCategory.PO,
    subcategory: ProductSubcategory.Rentals,
  },
  {
    id: 8,
    name: 'Bike Rental',
    price: '25',
    preorderBy: 2,
    description: PRODUCT_DESCRIPTIONS.bikeRental,
    preview: 'Per day or per stay bike rental',
    instructions: '',
    image: images.bikeRental,
    category: ProductCategory.PO,
    subcategory: ProductSubcategory.Rentals,
  },
  {
    id: 9,
    name: 'Use of Laundry Room',
    price: '20',
    preorderBy: 0,
    description: PRODUCT_DESCRIPTIONS.laundryRoom,
    preview: 'Grant access for guests to do their own laundry.',
    instructions: '',
    image: images.laundryService,
    category: ProductCategory.IH,
    subcategory: ProductSubcategory.GuestServices,
  },
  {
    id: 10,
    name: 'Toothbrush and Toothpaste',
    price: '10',
    preorderBy: 2,
    description: PRODUCT_DESCRIPTIONS.toothbrushAndPaste,
    preview: 'Forgot yours? Buy them here!',
    instructions: '',
    image: images.toothBrushAndPaste,
    category: ProductCategory.PO,
    subcategory: ProductSubcategory.GoodsAndEssentials,
  },
  {
    id: 11,
    name: 'Broken Wine Glass',
    price: '10',
    preorderBy: 0,
    description: PRODUCT_DESCRIPTIONS.brokenWineGlass,
    preview: 'A great way to get guests to pay for broken glassware',
    instructions: '',
    image: images.brokenWineGlass,
    category: ProductCategory.IH,
    subcategory: ProductSubcategory.GuestServices,
  },
  {
    id: 12,
    name: 'Breakfast (per person)',
    price: '25',
    preorderBy: 2,
    description: PRODUCT_DESCRIPTIONS.breakfast,
    preview: 'Serve your guests breakfast? Let them order here.',
    instructions: '',
    image: images.breakfast,
    category: ProductCategory.PO,
    subcategory: ProductSubcategory.FoodAndBeverage,
  },
  {
    id: 13,
    name: 'Extra coffee',
    price: '15',
    preorderBy: 2,
    description: PRODUCT_DESCRIPTIONS.extraCoffee,
    preview: 'Extra coffee for guests who need it!',
    instructions: '',
    image: images.extraCoffee,
    category: ProductCategory.PO,
    subcategory: ProductSubcategory.FoodAndBeverage,
  },
  {
    id: 14,
    name: 'Extra Linens',
    price: '25',
    preorderBy: 0,
    description: PRODUCT_DESCRIPTIONS.extraLinens,
    preview: 'Want to use the hide-a-bed? Need extra towels? Extra linens charge.',
    instructions: '',
    image: images.extraLinens,
    category: ProductCategory.IH,
    subcategory: ProductSubcategory.GuestServices,
  },
  {
    id: 15,
    name: 'Luggage Storage',
    price: '25',
    preorderBy: 2,
    description: PRODUCT_DESCRIPTIONS.luggageStorage,
    preview: 'Leave your bags at the house for extra time or drop them off early',
    instructions: '',
    image: images.luggageStorage,
    category: ProductCategory.PO,
    subcategory: ProductSubcategory.Rentals,
  },
  {
    id: 16,
    name: 'Ear Plugs',
    price: '5',
    preorderBy: 2,
    description: PRODUCT_DESCRIPTIONS.earPlugs,
    preview: 'When you need them, you need them!',
    instructions: '',
    image: images.earPlugs,
    category: ProductCategory.PO,
    subcategory: ProductSubcategory.GoodsAndEssentials,
  },
  {
    id: 17,
    name: 'Extra Guest Fee',
    price: '50',
    preorderBy: 2,
    description: PRODUCT_DESCRIPTIONS.extraGuest,
    preview: 'Charge for extra guests? Collect here!',
    instructions: '',
    image: images.extraGuest,
    category: ProductCategory.PO,
    subcategory: ProductSubcategory.AddOns,
  },
  {
    id: 18,
    name: 'Request Early Check-In',
    price: '25',
    preorderBy: 2,
    description: PRODUCT_DESCRIPTIONS.earlyCheckIn,
    preview: 'Know you can arrive early!',
    instructions: '',
    image: images.earlyCheckin,
    category: ProductCategory.PO,
    subcategory: ProductSubcategory.AddOns,
  },
  {
    id: 19,
    name: 'Pet Fee',
    price: '35',
    preorderBy: 2,
    description: PRODUCT_DESCRIPTIONS.petFee,
    preview: 'Extra pet fee',
    instructions: '',
    image: images.petFee,
    category: ProductCategory.PO,
    subcategory: ProductSubcategory.AddOns,
  },
  {
    id: 20,
    name: 'Request Late Check-Out',
    price: '25',
    preorderBy: 2,
    description: PRODUCT_DESCRIPTIONS.lateCheckOut,
    preview: 'Sleep in!',
    instructions: '',
    image: images.lateCheckout,
    category: ProductCategory.PO,
    subcategory: ProductSubcategory.AddOns,
  },
];

export const parseSuggestedProductsToCardItems = (
  selectedSubcategory?: ProductSubcategory,
  currency?: Currency,
) => {
  return suggestedProductsData
    .filter(({ subcategory: productSubcategory }) =>
      !selectedSubcategory ? true : productSubcategory === selectedSubcategory,
    )
    .map(({ id, name, description, preview, image, subcategory, price }) => ({
      id,
      title: name,
      description,
      preview,
      image,
      subheader: subcategory,
      footer: (
        <span>
          suggested price <b>{formatCurrency(price, currency)}</b>
        </span>
      ),
    }));
};

export default suggestedProductsData;
