import React, { useCallback } from 'react';
import dayjs from 'dayjs';

import { Button } from 'ui-kit';

import { PurchaseStatus, PurchaseStatusMap } from 'api/types/order';
import { exportOrderProducts } from 'api/order_product_sdk';
import { ProductCategoryMap } from 'api/types/product';

import { EDateFilter } from 'pages/OrdersList/constants';

import { getLocalDatetime } from 'utils/datetime';
import { downloadListOfListsAsCSV } from 'utils/csv';

export interface IOrdersExportButton {
  selectedFilters: {
    selectedStore?: number;
    selectedStatus?: PurchaseStatus;
    startDate?: Date;
    endDate?: Date;
    dateFilter?: EDateFilter;
  };
  disabled: boolean;
}

const OrderProductsExportButton = ({ selectedFilters, disabled }: IOrdersExportButton) => {
  const handleExportProducts = useCallback(
    () =>
      exportOrderProducts({
        storeId: selectedFilters.selectedStore,
        status: selectedFilters.selectedStatus,
        startDate: selectedFilters.startDate
          ? getLocalDatetime(selectedFilters.startDate, 'YYYY-MM-DD')
          : undefined,
        endDate: selectedFilters.endDate
          ? getLocalDatetime(selectedFilters.endDate, 'YYYY-MM-DD')
          : undefined,
        dateFilter: selectedFilters.dateFilter,
      }).then((res) => {
        const dataForCSV = [
          [
            'Date of Service',
            'Product',
            'Quantity',
            'Item price',
            'Product category',
            'Store',
            'Guest name',
            'Guest email',
            'Guest phone',
            'Sale number',
            'Date of purchase',
            'Status',
            'Guest paid',
            'Your profit',
            'Is refunded',
          ],
          ...res.map((product) => [
            dayjs(product.delivery_date).format('Do MMM YYYY'),
            product.product_name,
            product.quantity,
            product.price,
            ProductCategoryMap[product.category],
            product.store_name,
            product.guest.guest_name,
            product.guest.guest_email,
            product.guest.guest_phone,
            product.order_number,
            dayjs(product.date_created).format('Do MMM YYYY'),
            PurchaseStatusMap[product.status],
            product.guest_amount,
            product.owner_profit,
            product.is_refunded ? 'Yes' : 'No',
          ]),
        ];

        downloadListOfListsAsCSV({
          data: dataForCSV,
          filename: 'services_calendar.csv',
        });
      }),
    [selectedFilters],
  );

  return (
    <Button variant="contained" onClick={handleExportProducts} disabled={disabled}>
      Export
    </Button>
  );
};

export default OrderProductsExportButton;
