/* eslint-disable react/prop-types */
import React, { Suspense, lazy, useEffect } from 'react';
import { Spin } from 'antd';
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';
import { DashboardRoute } from './DashboardRoute';
import { StoreRoute } from './StoreRoute';
import { OverviewRoute } from './OverviewRoute';
import DashboardRoutes from './DashboardRoutes';
import OverviewRoutes from './OverviewRoutes';
import { useAuth } from 'contexts/auth/auth';
import SignUpPage from '../../pages/onboarding/SignUpPage';
import ForgotPasswordPage from 'pages/forgot-password/ForgotPasswordPage';
import ResetPasswordPage from 'pages/reset-password/ResetPasswordPage';
import { useOwnerDetails } from '../../api/owners';
import { Role } from 'api/types/auth';
import { OnboardingRoute } from './OnboardingRoute';
import GetStartedPage from 'pages/get-started/GetStartedPage';
import CoHostSetPasswordPage from 'pages/CoHostSetPasswordPage';
import CheckoutPage from 'pages/CheckoutPage';
import CartDetailsPage from 'pages/CartDetailsPage';
import { URLS } from './urls';
import PurchaseRequestPage from 'pages/manage-request/PurchaseRequestPage';
import PurchaseRequestConfirmation from 'pages/manage-request/PurchaseRequstConfirmation';
import CheckoutDetailsPage from 'pages/CheckoutDetailsPage';

const LoginPage = lazy(() =>
  import(
    /* webpackChunkName: "store" */
    'components/LoginPage'
  ),
);

const Store = lazy(() =>
  import(
    /* webpackChunkName: "store" */
    'components/Store/Store'
  ),
);

const OnboardingPage = lazy(() =>
  import(
    /* webpackChunkName: "store" */
    'pages/onboarding/OnboardingPage'
  ),
);

const LocalProductSearchPage = lazy(() =>
  import(
    /* webpackChunkName: "store" */
    'pages/local-product-search/LocalProductSearchPage'
  ),
);

const SubscriptionPage = lazy(() =>
  import(
    /* webpackChunkName: "store" */
    'pages/subscription/SubscriptionPage.container'
  ),
);

const storeRoutes = ['/store/:id/', '/store/:id/:category'];

const validStoreId = (storeId) => {
  return /^\d+$/.test(storeId);
};

export function RootPathRedirect({ role, ownerId }) {
  const location = useLocation();
  const storeId = location.pathname.replaceAll('/', '');

  const params = new URLSearchParams(location.search);

  if (validStoreId(storeId)) {
    return (
      <Redirect
        to={{
          pathname: `/store/${storeId}/`,
          search: params.toString(),
        }}
      />
    );
  }

  if (role !== Role.HOST) {
    return <Redirect to="/login/" />;
  }

  const { data, isLoading } = useOwnerDetails(ownerId);

  if (!isLoading) {
    return (
      <Redirect
        to={{
          pathname:
            data.stores.length === 1 ? `/dashboard/store/${data.stores[0].id}` : '/overview/',
        }}
      />
    );
  }
  return <Spin />;
}

export function Routes() {
  const { search } = useLocation();
  const { role, id: ownerId } = useAuth();
  const location = useLocation();
  const background = location.state && location.state.background;

  useEffect(() => {
    setTimeout(() => window.scroll({ top: 0, behavior: 'smooth' }), 10);
  }, [location.pathname]);

  useEffect(() => {
    const nextParam = new URLSearchParams(search).get('next');
    if (nextParam) {
      localStorage.setItem('nextUrl', nextParam);
    }
  }, []);

  return (
    <Suspense fallback={<Spin />}>
      <Switch location={background ?? location}>
        {/* Appending trailing slashes to all the routes */}
        <Route
          path="/:url*"
          exact
          strict
          render={(props) =>
            props.location.search ? (
              <Redirect
                to={{
                  pathname: `${props.location.pathname}${props.location.search}/`,
                  state: props.location.state,
                }}
              />
            ) : (
              <Redirect
                to={{
                  pathname: `${props.location.pathname}/`,
                  state: props.location.state,
                }}
              />
            )
          }
        />
        <Route path="/get-started/" component={GetStartedPage} />
        <Route path="/co-host/setup-password/:invitationUUID/" component={CoHostSetPasswordPage} />
        <Route path="/login/" component={LoginPage} />
        <Route path="/forgot-password/" component={ForgotPasswordPage} />
        <Route path="/reset-password/:token?" component={ResetPasswordPage} />
        <Route path="/signup/" component={SignUpPage} />
        <Route path="/pricing/" component={SubscriptionPage} />
        {/* TODO: move the url in URLS */}
        <Route
          path="/:actorType(owner|guest|merchant)/:action(approve|deny|cancel)"
          component={PurchaseRequestConfirmation}
        />
        {/* TODO: move the url in URLS */}
        <Route
          path="/:actorType(owner|guest|merchant)/:purchaseUUID"
          component={PurchaseRequestPage}
        />
        <Route path="/vendors/" component={LocalProductSearchPage} />
        <Route path="/new-store/">
          <Redirect to="/signup/" />
        </Route>
        <OnboardingRoute path="/onboarding/" />
        <StoreRoute path="/store">
          <Switch>
            <Route path={URLS.CART_DETAILS} component={CartDetailsPage} />,
            <Route path={URLS.CHECKOUT} component={CheckoutPage} />,
            <Route path={URLS.CHECKOUT_DETAILS} component={CheckoutDetailsPage} />,
            <Route path={storeRoutes} exact component={Store} />
          </Switch>
        </StoreRoute>

        <OverviewRoute path="/overview/">
          <OverviewRoutes />
        </OverviewRoute>

        <DashboardRoute path="/dashboard/">
          <DashboardRoutes />
        </DashboardRoute>

        <Route>
          <RootPathRedirect role={role} ownerId={ownerId} />
        </Route>
      </Switch>
      {background && <Route path="/onboarding/" component={OnboardingPage} />}
    </Suspense>
  );
}
