import React, { useState } from 'react';
import { message } from 'antd';

import { productCreate } from 'api/product_sdk';
import { useProductsList } from 'api/products';

import NewProductStepper from 'components/NewProductStepper';
import ConfirmModal from 'components/ConfirmModal';
import { NewProductMode } from 'components/NewProductStepper/NewProductStepper';

import { Modal, Typography } from 'ui-kit';
import { useStore } from 'contexts/store';

import './NewProductModal.scss';
import _ from 'lodash';
import { useLoadingBackdrop } from 'hooks/useLoadingBackdrop';

interface Props {
  isOpen: boolean;
  mode?: NewProductMode;
  onCancel: () => void;
}

const NewProductModal: React.FC<Props> = ({ isOpen, onCancel, mode = 'default' }) => {
  const [showLoading, hideLoading] = useLoadingBackdrop();

  const [isConfirmModalVisible, setConfirmModalVisible] = useState(false);
  const { id: storeId } = useStore();
  const [massCreate, setIsMassCreate] = useState<boolean>(false);

  const toogleConfirmModal = () => {
    setConfirmModalVisible((prev) => !prev);
  };

  const onConfirmClose = () => {
    toogleConfirmModal();
    onCancel();
  };

  const { mutate: mutateProductList } = useProductsList({
    storeId,
    sort: 'store_arrangement',
  });

  const handleSubmit = (product) => {
    showLoading();
    return productCreate({ storeId, massCreate, ...product })
      .then((res) => {
        hideLoading();
        mutateProductList();
        if (massCreate) {
          if (!_.isNull(res.product_group_id)) {
            message.success('Product successfully created and tile added to all stores!');
          } else {
            message.warning(
              'Product successfully created, but tile cannot be added to all stores because it is already in all stores.',
            );
          }
        } else {
          message.success('Product successfully created!');
        }
        onCancel();
      })
      .catch((error) => {
        hideLoading();
        const errorMessage = _.has(error, 'message') ? error.message : null;

        // if the error message is null, check if there are other keys
        if (_.isNull(errorMessage) && !_.isEmpty(error)) {
          Object.keys(error).forEach((key) => {
            message.error(`${_.lowerCase(key)}: ${error[key][0]}`);
          });
        } else {
          message.error(errorMessage || 'Something went wrong! Please try again later.');
        }
      });
  };

  const onChangeStep = () => {
    const modalElement = document.getElementsByClassName('ant-modal-wrap')?.[0];
    modalElement.scrollTop = 0;
  };

  return (
    <>
      <Modal
        className="NewProductModal"
        visible={isOpen}
        footer={null}
        destroyOnClose
        maskClosable={false}
        onCancel={toogleConfirmModal}
      >
        <NewProductStepper
          onCancel={toogleConfirmModal}
          onSubmit={handleSubmit}
          onChangeStep={onChangeStep}
          mode={mode}
          massCreate={massCreate}
          setIsMassCreate={setIsMassCreate}
        />
      </Modal>

      <ConfirmModal
        visible={isConfirmModalVisible}
        confirmText="Confirm"
        cancelText="Cancel"
        onCancel={toogleConfirmModal}
        onConfirm={onConfirmClose}
        destroyOnClose
      >
        <>
          <Typography variant="h4">Are you sure?</Typography>
          <Typography variant="body2" color="textGray">
            You will lose all the filled info.
          </Typography>
        </>
      </ConfirmModal>
    </>
  );
};

export default NewProductModal;
