import React, { useMemo } from 'react';
import Dashboard from 'components/Dashboard/Dashboard.container';
import { Typography, Spin } from 'antd';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import OrdersTable from 'components/Orders/OrdersTable';
import { useParams } from 'react-router';
import { PurchaseStatus } from 'api/types/order';
import { useOrders } from 'api/order_sdk';
import OrdersExportButton from 'components/OrdersExportButton';
import './styles.scss';

dayjs.extend(advancedFormat);

export default function SalesPage() {
  const { storeId } = useParams();
  const {
    data: ordersData,
    isLoading,
    currentPage,
    count,
    setPage,
    mutate: refetchOrders,
  } = useOrders({
    storeId,
    status: PurchaseStatus.COMPLETED,
  });

  const orders = useMemo(() => {
    return ordersData?.results || [];
  }, [ordersData]);

  return (
    <Dashboard page="sales" storeId={storeId} doShowHeader>
      <Spin spinning={isLoading}>
        <div className="DashboardSales">
          <div className="DashboardSales_header">
            <Typography.Title className="DashboardSales_title" level={1}>
              Sales report
            </Typography.Title>
          </div>

          <div className="DashboardSales_body">
            <OrdersTable
              orders={orders}
              currentPage={currentPage}
              count={count}
              setPage={setPage}
              inStoreContext={true}
              onRefundCompleted={refetchOrders}
            />

            <div style={{ float: 'right' }}>
              <OrdersExportButton
                selectedFilters={{
                  selectedStore: storeId,
                  selectedStatus: PurchaseStatus.COMPLETED,
                }}
                disabled={orders.length === 0}
              />
            </div>
          </div>
        </div>
      </Spin>
    </Dashboard>
  );
}
