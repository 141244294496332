import { ActorSnippet, ContactInfoSnippet, UserDisabledSnippet, UserSnippet } from './auth';
import { PayoutMethodSnippet } from './payout';
import { CountryCode, Currency, StoreSnippet } from './store';

export enum NotifyBy {
  NO = 'NO',
  SM = 'SM',
  EM = 'EM',
  PU = 'PU',
}

export enum PaymentPlan {
  Basic = 'BASIC',
  Pro = 'PRO',
  ProPlus = 'PRO_PLUS',
  Enterprise = 'ENTERPRISE',
}

export const paymentPlanOrder = {
  [PaymentPlan.Basic]: 0,
  [PaymentPlan.Pro]: 1,
  [PaymentPlan.ProPlus]: 2,
  [PaymentPlan.Enterprise]: 3,
};

export interface OwnerNameSnippet {
  id: number;
  actor: number;
  firstName: string;
  lastName: string;
  email: string;
  image: string | null;
  promoCode: string | null;
}

export interface Owner {
  id: number;
  dateCreated: string;
  dateModified: string;
  image: string | null;
  isActive: boolean;
  deviceId: string | null;
  registrationId: string | null;
  notifyBy: NotifyBy;
  isComplete: boolean;
  commisionLow: string;
  commisionHigh: string;
  commisionCutoff: string;
  country: CountryCode;
  currency: Currency;
  promoCode: string | null;
  hasClosedTutorial: boolean;
  actor: number;
  plan: PaymentPlan;
  planLookupKey: string;
  isPlanEnding: boolean;
}

export interface OwnerDetail extends Omit<Owner, 'actor'> {
  actor: ActorSnippet;
  user: UserSnippet;
  contactInfos: ContactInfoSnippet[];
  stores: StoreSnippet[];
  payoutMethods: PayoutMethodSnippet[];
  disabledReasons: UserDisabledSnippet[];
  totalAmountOwed: string;
}

export interface IOwnerPhoneNotificationSettings {
  id: number;
  phoneNumber: string;
  isSalesSmsEnabled: boolean;
  isRequestSmsEnabled: boolean;
  isMarketingSmsEnabled: boolean;
}
