import React, { useCallback, useEffect, useRef, useState } from 'react';
import InputNew, { InputProps } from 'ui-kit/Input/InputNew';
import _debounce from 'lodash/debounce';
import FormControl, { FormControlProps } from 'ui-kit/FormControl/FormControl';
import IconButton from 'ui-kit/IconButton';
import {
  Eye as EyeShowIcon,
  EyeOff as EyeHideIcon,
  Search as SearchIcon,
  X as ClearIcon,
} from 'react-feather';

interface Props extends InputProps, Omit<FormControlProps, 'children'> {
  inputRef?: React.Ref<HTMLInputElement>;
  showHelperIcon?: boolean;
}

interface SearchProps extends Omit<Props, 'onChange'> {
  onChange: (value: string) => void;
  initialValue: string;
}

const TextField = React.forwardRef<HTMLDivElement, Props>(
  (
    {
      label,
      required,
      helperText,
      error,
      errorMessage,
      fullWidth = false,
      disabled = false,
      inputRef = null,
      showHelperIcon = true,
      ...rest
    },
    ref,
  ) => {
    return (
      <FormControl
        ref={ref}
        label={label}
        required={required}
        helperText={helperText}
        error={error}
        errorMessage={errorMessage}
        fullWidth={fullWidth}
        showHelperIcon={showHelperIcon}
      >
        <InputNew inputRef={inputRef} error={error} disabled={disabled} {...rest} />
      </FormControl>
    );
  },
);

export const TextFieldPassword = React.forwardRef<React.RefObject<HTMLElement>, Props>((props) => {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <TextField
      type={showPassword ? 'text' : 'password'}
      InputProps={{
        endAdornment: (
          <IconButton color="textGray" onClick={() => setShowPassword((prev) => !prev)}>
            {showPassword ? <EyeShowIcon /> : <EyeHideIcon />}
          </IconButton>
        ),
      }}
      {...props}
    />
  );
});

export const TextFieldSearch = React.forwardRef<React.RefObject<HTMLElement>, SearchProps>(
  ({ onChange, initialValue = null, ...rest }) => {
    const [value, setValue] = useState(initialValue);
    const inputRef = useRef<HTMLInputElement>(null);

    const handleAction = () => {
      inputRef.current.focus();
      if (value) setValue('');
    };

    const debounceOnChange = useCallback(
      _debounce((value) => onChange(value), 300),
      [],
    );

    useEffect(() => {
      if (value !== null) debounceOnChange(value);
    }, [value]);

    useEffect(() => {
      if (value) inputRef.current.focus();
    }, []);

    return (
      <TextField
        inputRef={inputRef}
        type="text"
        InputProps={{
          endAdornment: (
            <IconButton color="textGray" onClick={handleAction}>
              {value ? <ClearIcon /> : <SearchIcon />}
            </IconButton>
          ),
        }}
        onChange={(e) => setValue(e.target.value)}
        value={value ?? ''}
        {...rest}
      />
    );
  },
);

export default TextField;
