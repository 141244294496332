import React, { useMemo } from 'react';

import { IGuestCartDetail } from 'api/guest_sdk';
import { Currency } from 'api/types/store';
import { Tooltip, Typography } from 'ui-kit';
import Stack from 'ui-kit/Stack';
import { formatCurrency } from 'utils';
import dayjs from 'dayjs';
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  ExclamationCircleOutlined,
  FieldTimeOutlined,
  LoadingOutlined,
} from '@ant-design/icons';
import CountControls from 'components/Store/NewCheckout/CountControls';
import { Avatar, Col, Row } from 'antd';
import placeholder from 'components/Dashboard/Store/Products/images/placeholder.png';
import './styles.scss';
import { GuestPurchaseStatusMap, PurchaseStatus } from 'api/types/order';

export interface IOrderProductProps {
  currency: Currency;
  orderProduct: IGuestCartDetail['order_products'][number];
  onDeductItem?: () => void;
  onAddItem?: () => void;
  showCountControls?: boolean;
  showPurchaseStatus?: boolean;
}

const OrderProduct = ({
  orderProduct,
  currency,
  onAddItem,
  onDeductItem,
  showCountControls = true,
  showPurchaseStatus = false,
}: IOrderProductProps) => {
  const productCategory = useMemo(() => {
    if (orderProduct.is_preorder) {
      return `Requested for ${dayjs(orderProduct.delivery_date).format('MMM DD, YYYY')}`;
    } else {
      return `In-Home`;
    }
  }, [orderProduct]);

  const productCharge = useMemo(() => {
    if (orderProduct.product_info.requires_approval) {
      return 'Requested items are pending, with a hold appearing on your payment method.';
    }
  }, [orderProduct]);

  const purchaseStatusIcon = useMemo(() => {
    if (orderProduct.purchase_status === PurchaseStatus.PROCESSING) {
      return (
        <LoadingOutlined
          style={{
            color: 'gray',
            fontSize: '16px',
          }}
        />
      );
    }

    if (orderProduct.purchase_status === PurchaseStatus.HOLD) {
      return (
        <FieldTimeOutlined
          style={{
            color: 'gray',
            fontSize: '16px',
          }}
        />
      );
    }

    if (orderProduct.purchase_status === PurchaseStatus.COMPLETED) {
      return (
        <CheckCircleOutlined
          style={{
            color: 'green',
            fontSize: '16px',
          }}
        />
      );
    }

    if (
      orderProduct.purchase_status === PurchaseStatus.CANCEL ||
      orderProduct.purchase_status === PurchaseStatus.FAILED
    ) {
      return (
        <CloseCircleOutlined
          style={{
            color: 'red',
            fontSize: '16px',
          }}
        />
      );
    }
  }, [orderProduct]);

  return (
    <Row
      gutter={[0, 8]}
      style={{ margin: '10px 0', padding: '15px', border: '1px solid #f0f0f0', width: '100%' }}
    >
      <Col xs={24} md={12}>
        <Stack justifyContent="space-between" alignItems="flex-start" gap="16px">
          <Stack style={{ alignSelf: 'flex-start' }}>
            <Avatar
              className="OrderProduct_image"
              src={orderProduct.product_info.image || placeholder}
              shape="square"
            />
          </Stack>
          <Stack flexDirection="column" style={{ flex: 1 }}>
            <Typography variant="subtitle2" style={{ fontWeight: 700 }}>
              {orderProduct.product_info.product_name}
            </Typography>
            <Stack justifyContent="space-between" alignItems="center" gap="32px">
              <Typography variant="body2" color="primary">
                {productCategory}
              </Typography>
            </Stack>
            <Stack justifyContent="space-between" alignItems="center" style={{ width: '100%' }}>
              <Typography
                variant="body2"
                style={{
                  marginTop: '16px',
                  color: orderProduct.product_info.requires_approval ? 'inherit' : 'green',
                }}
              >
                {productCharge}
              </Typography>
              {orderProduct.product_info.requires_approval && (
                <FieldTimeOutlined
                  style={{
                    color: 'gray',
                    fontSize: '16px',
                  }}
                />
              )}
            </Stack>
          </Stack>
        </Stack>
      </Col>
      <Col xs={24} md={12}>
        <Stack flexDirection="column" alignItems="flex-end" style={{ flex: 1 }}>
          <Typography variant="subtitle1" style={{ fontWeight: 700 }}>
            {formatCurrency(orderProduct.guest_amount, currency)}{' '}
            {orderProduct.product_info.is_guest_price && (
              <Tooltip title="Variable price">
                <ExclamationCircleOutlined />
              </Tooltip>
            )}
          </Typography>
          {showCountControls && onDeductItem && onAddItem && (
            <div style={{ marginBottom: '16px' }}>
              <CountControls
                orderProduct={orderProduct}
                onDeduct={onDeductItem}
                onAddQuantity={onAddItem}
              />
            </div>
          )}
          <Typography variant="body2">
            Item price: {formatCurrency(Number(orderProduct.price), currency)}
          </Typography>
          <Typography variant="body2">
            Fees: {formatCurrency(Number(orderProduct.convenience_fee), currency)}
          </Typography>
          {showPurchaseStatus && (
            <div>
              <Typography variant="body1" className="OrderProduct_purchaseStatus">
                Payment status: {GuestPurchaseStatusMap[orderProduct.purchase_status]}{' '}
                {purchaseStatusIcon}
              </Typography>
              {orderProduct.purchase_failure_message && (
                <Typography variant="body1" className="OrderProduct_purchaseFailureMessage">
                  {orderProduct.purchase_failure_message}
                </Typography>
              )}
            </div>
          )}
        </Stack>
      </Col>
    </Row>
  );
};

export default OrderProduct;
