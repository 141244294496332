import { getGuestOrderDetails } from 'api/guest_sdk';
import { useCallback, useEffect, useState } from 'react';

const POLLING_INTERVAL = 3000; // Poll every 3 seconds

// Polling Hook
export const usePollingCheckoutDetails = (orderId: number, interval: number = POLLING_INTERVAL) => {
  const [isTaskReady, setIsTaskReady] = useState(false);
  const [isPolling, setIsPolling] = useState(true);

  const pollTaskStatus = useCallback(async () => {
    try {
      if (orderId === null) {
        return;
      }

      const { is_ready } = await getGuestOrderDetails({ orderId });

      if (is_ready) {
        setIsTaskReady(true);
        setIsPolling(false); // Stop polling when task is ready
      }
    } catch (error) {
      setIsPolling(false); // Stop polling on error
    }
  }, [orderId]);

  useEffect(() => {
    if (isPolling) {
      const pollingInterval = setInterval(() => {
        pollTaskStatus();
      }, interval);

      return () => clearInterval(pollingInterval);
    }
  }, [pollTaskStatus, isPolling, interval]);

  return { isTaskReady, isPolling };
};
