import React, { useState, useRef, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Alert, Row, Divider, message } from 'antd';

import 'components/LoginPage/LoginPage.scss';
import { Link, Typography } from 'ui-kit';
import UnauthLayout from 'components/UnauthLayout';
import ResetPasswordForm from './ResetPasswordForm';
import { resetPassword } from 'api/owner_sdk';
import { ERROR_MESSAGE_EXPIRE_TOKEN } from './constants';
import { useAuth } from 'contexts/auth/auth';

export default function ResetPasswordPage() {
  const history = useHistory();
  const { hostLogin } = useAuth();
  const { token } = useParams<{ token: string }>();
  const [error, setError] = useState<string | React.ReactNode>('');
  const alertRef = useRef(null);

  useEffect(() => {
    if (error) {
      alertRef.current?.focus();
    }
  }, [error]);

  const handleLoginUser = async ({ email, password }) => {
    try {
      await hostLogin(email, password);
      history.push('/overview/stores/');
    } catch (error) {
      message.error(error.message);
    }
  };

  const handleSubmit = ({ password }) => {
    return resetPassword({ password, token })
      .then(({ email }) => {
        handleLoginUser({ email, password });
      })
      .catch((error) => {
        message.error(error.message);
        if (error.message === ERROR_MESSAGE_EXPIRE_TOKEN) {
          // When we return the error that the token does not exist or expired, we want to show this specific message with CTA links
          setError(
            <div>
              This link is used or expired. You can get a new link{' '}
              <Link href="/forgot-password">here</Link> or you can login with your newly set
              password <Link href="/login">here</Link>.
            </div>,
          );
        } else {
          setError(error.message);
        }
      });
  };

  return (
    <UnauthLayout>
      {error && (
        <Row justify="center" ref={alertRef} tabIndex={-1}>
          <Alert className="LoginPage_alert" type="error" message={error} />
        </Row>
      )}
      <div>
        <Typography variant="h6">Set your password</Typography>
        <Divider />
      </div>

      <ResetPasswordForm onSubmit={handleSubmit} />
    </UnauthLayout>
  );
}
