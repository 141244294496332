import React, { useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import _ from 'lodash';

import { useOrders } from 'api/order_sdk';

import OverviewLayout from 'components/OverviewLayout/OverviewLayout';
import OrdersExportButton from 'components/OrdersExportButton';
import Filters from 'components/Filters';
import OrdersTable from 'components/Orders/OrdersTable';

import { EDateFilter } from './constants';
import { getLocalDatetime } from 'utils/datetime';

import './styles.scss';

const OrdersList = () => {
  const [selectedStore, setSelectedStore] = useState(undefined);
  const [selectedStatus, setSelectedStatus] = useState(undefined);
  const [selectedDateFilter, setSelectedDateFilter] = useState(EDateFilter.DATE_CREATED);
  const [startDate, setStartDate] = useState<Date | undefined>(undefined);
  const [endDate, setEndDate] = useState<Date | undefined>(undefined);
  const {
    data: ordersData,
    currentPage,
    count,
    setPage,
    setCurrentPage,
    setOffset,
    mutate: refetchOrders,
    isLoading,
  } = useOrders({
    storeId: selectedStore,
    status: selectedStatus,
    dateFilter: selectedDateFilter,
    startDate: startDate ? getLocalDatetime(startDate, 'YYYY-MM-DD') : undefined,
    endDate: endDate ? getLocalDatetime(endDate, 'YYYY-MM-DD') : undefined,
  });

  const orders = useMemo(() => {
    return ordersData?.results || [];
  }, [ordersData]);

  return (
    <OverviewLayout>
      <Helmet>
        <title>Recent Orders | The Host Co</title>
      </Helmet>
      <p className="page-title">Recent Orders</p>

      <Filters
        selectedStore={selectedStore}
        setSelectedStore={setSelectedStore}
        selectedStatus={selectedStatus}
        setSelectedStatus={setSelectedStatus}
        selectedDateFilter={selectedDateFilter}
        setSelectedDateFilter={setSelectedDateFilter}
        disableSelectedDateFilter={true}
        startDate={startDate}
        setStartDate={setStartDate}
        endDate={endDate}
        setEndDate={setEndDate}
        setCurrentPage={setCurrentPage}
        setOffset={setOffset}
      />
      <OrdersTable
        loading={isLoading}
        orders={orders}
        currentPage={currentPage}
        count={count}
        setPage={setPage}
        onRefundCompleted={refetchOrders}
      />

      <div style={{ float: 'right' }}>
        <OrdersExportButton
          selectedFilters={{
            selectedStore,
            selectedStatus,
            startDate,
            endDate,
            dateFilter: selectedDateFilter,
          }}
          disabled={orders.length === 0}
        />
      </div>
    </OverviewLayout>
  );
};

export default OrdersList;
