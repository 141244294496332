import { useFetch, post, BASE_URL, get } from 'utils/sdk';
import { formatGetParams } from 'utils/utilsV2';
import { formatDateToQueryParams } from '../utils/datetime';
import { Currency } from './types/store';
import { BalanceSign } from './types/balances';
import { PayoutStatus } from './types/payout';

export interface IOwnerPhoneNotificationSettings {
  id: number;
  phone_number: string;
  is_request_sms_enabled: boolean;
  is_sales_sms_enabled: boolean;
  is_marketing_sms_enabled: boolean;
}

export interface IOwnerPayouts {
  first_date_created: Date;
  last_date_created: Date;
  total_owner_amount: number;
  total_owner_amount_after_balances: number;
  currency: Currency;
  order_products_are_paid: boolean;

  store: { id: number; store_name: string; owner_profit_percentage: number };

  payouts: Array<{ id: number; status: PayoutStatus }>;
}

export interface IOwnerPayoutOrderProduct {
  product_name: string;
  price: number;
  quantity: number;
  amount: number;
  amount_refunded: number;
  transaction_fee: number;
  owner_amount_refunded: number;
  hostco_profit: number;
  owner_profit: number;
  delivery_date: string;
}

export interface IOwnerPayoutApplicableBalances {
  date_created: Date;
  amount: number;
  sign: BalanceSign;
  external_note: string;
}

export interface IOwnerPayoutNotApplicableBalances {
  leftover_amount: number;
  sign: BalanceSign;
  external_note: string;
}

export interface IOwnerPayoutOrderProductDetailsSettings {
  order_products: Array<IOwnerPayoutOrderProduct>;
  store: { store_name: string };
  currency: string;
  applied_balances?: Array<IOwnerPayoutApplicableBalances>;
  not_applied_balances?: Array<IOwnerPayoutNotApplicableBalances>;
  total_owner_amount_after_balances: number;
}

export interface IOwnerPayoutExport extends IOwnerPayouts {
  order_products: Array<IOwnerPayoutOrderProduct>;
  applied_balances?: Array<IOwnerPayoutApplicableBalances>;
  not_applied_balances?: Array<IOwnerPayoutNotApplicableBalances>;
}

export interface IOwnerPayoutOrderProductDetailsExport {
  order_products: Array<{
    product_name: string;
    price: number;
    quantity: number;
    amount: number;
    transaction_fee: number;
    amount_refunded: number;
    hostco_profit: number;
    owner_profit: number;
    delivery_date: string;
  }>;
  store: { store_name: string };
  currency: string;
}

export const ownerPhoneNotificationsSettingsUpdate = ({
  phoneNotificationSettings,
}: {
  phoneNotificationSettings: Array<IOwnerPhoneNotificationSettings>;
}) =>
  post(`${BASE_URL}services/owner/phone-notification-settings/update/`, {
    phone_notification_settings: phoneNotificationSettings,
  });

export const useOwnerPhoneNotificationSettings = () =>
  useFetch<Array<IOwnerPhoneNotificationSettings>>(
    `services/owner/phone-notification-settings/retrieve/`,
  );

export const useOwnerPayouts = ({ startDate, endDate }: { startDate: Date; endDate: Date }) => {
  const formattedStartDate = formatDateToQueryParams(startDate);
  const formattedEndDate = formatDateToQueryParams(endDate);

  const params = {
    start_date: formattedStartDate,
    end_date: formattedEndDate,
  };

  return useFetch<Array<IOwnerPayouts>>(
    `services/owner/payouts/${formatGetParams({
      ...params,
    })}`,
  );
};

export const getOwnerPayoutsExport = ({
  startDate,
  endDate,
}: {
  startDate: Date;
  endDate: Date;
}) => {
  const formattedStartDate = formatDateToQueryParams(startDate);
  const formattedEndDate = formatDateToQueryParams(endDate);

  const params = {
    start_date: formattedStartDate,
    end_date: formattedEndDate,
  };

  return get<Array<IOwnerPayoutExport>>(
    `${BASE_URL}services/owner/payouts-export/${formatGetParams({
      ...params,
    })}`,
  );
};

export const useOwnerPayoutDetails = ({
  storeId,
  payoutStartDate,
  payoutEndDate,
}: {
  storeId: number;
  payoutStartDate: Date;
  payoutEndDate: Date;
}) => {
  const formattedStartDate = new Date(payoutStartDate).toISOString().split('T')[0];
  const formattedEndDate = new Date(payoutEndDate).toISOString().split('T')[0];

  const params = {
    store_id: storeId,
    payout_start_date: formattedStartDate,
    payout_end_date: formattedEndDate,
  };
  return useFetch<IOwnerPayoutOrderProductDetailsSettings>(
    `services/owner/payout-details/${formatGetParams({
      ...params,
    })}`,
  );
};

export const exportPayoutDetails = ({
  storeId,
  payoutStartDate,
  payoutEndDate,
}: {
  storeId: number;
  payoutStartDate: Date;
  payoutEndDate: Date;
}) => {
  const formattedStartDate = new Date(payoutStartDate).toISOString().split('T')[0];
  const formattedEndDate = new Date(payoutEndDate).toISOString().split('T')[0];

  const params = {
    store_id: storeId,
    payout_start_date: formattedStartDate,
    payout_end_date: formattedEndDate,
  };
  return get<IOwnerPayoutOrderProductDetailsExport>(
    `${BASE_URL}services/owner/payout-details-export/${formatGetParams({
      ...params,
    })}`,
  );
};

export const generateToken = ({ email }: { email: string }) => {
  return post(`${BASE_URL}services/password-reset/generate-reset-token/`, { email });
};

export const resetPassword = ({ password, token }: { password: string; token: string }) => {
  return post<{ email: string }>(`${BASE_URL}services/password-reset/reset-password/`, {
    password,
    token,
  });
};
