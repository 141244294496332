import React from 'react';
import { ProductCategory } from 'api/types/product';
import { Controller, useFormContext } from 'react-hook-form';
import { Radio, RadioGroup, Spinner, TextField } from 'ui-kit';
import FormControl from 'ui-kit/FormControl';
import QuantityField from './QuantityField';
import AlertTresholdField from './AlertThresholdField';
import DelegatesField from './DelegatesField';
import './ProductSettingsBaseForm.scss';
import ExtraFields from './ExtraFields';
import { useAuth } from 'contexts/auth/auth';
import { useOwnerDetails } from 'api/owners';
import { PaymentPlan, paymentPlanOrder } from 'api/types/owner';
import _ from 'lodash';

const BASE_CLASS = 'ProductSettingsBaseForm';

export default function ProductSettingsBaseForm() {
  const { control, watch, setError, setValue } = useFormContext();
  const { id: ownerId } = useAuth();
  const { data, isLoading } = useOwnerDetails(ownerId);
  const { plan } = data || {};

  const productCategory = watch('product.category');
  const handleCategoryChange = (e) => {
    setValue('product.category', e.target.value, { shouldValidate: true, shouldDirty: true });
    setValue('product.requiresApproval', false, { shouldDirty: true });

    // When changing to immediate availability, we should reset preorderBy to 0
    if (e.target.value === ProductCategory.IH) {
      setValue('product.preorderBy', 0, { shouldDirty: true });
    }
  };

  if (isLoading) {
    return <Spinner />;
  }
  return (
    <div className={BASE_CLASS}>
      <Controller
        control={control}
        name="product.category"
        render={({ field }) => {
          return (
            <FormControl label="Is this product available immediately after purchase?" required>
              <RadioGroup {...field} onChange={handleCategoryChange}>
                <div>
                  <Radio value={ProductCategory.PO}>No, I need notice ahead of time</Radio>
                  <Radio value={ProductCategory.IH}>Yes, it will be available immediately</Radio>
                  <Radio
                    value={ProductCategory.LINK}
                    disabled={paymentPlanOrder[plan] < paymentPlanOrder[PaymentPlan.Pro]}
                  >
                    This product links to an external website and is not purchaseable
                  </Radio>
                </div>
              </RadioGroup>
            </FormControl>
          );
        }}
      />
      {productCategory === ProductCategory.PO && (
        <>
          <Controller
            control={control}
            name="product.preorderBy"
            render={({ field: { value, ...restField }, fieldState: { error } }) => {
              return (
                <TextField
                  label="How many days notice do you need?"
                  required
                  type="number"
                  value={value ?? ''}
                  className="quantity-field"
                  {...restField}
                  error={!_.isUndefined(error)}
                  errorMessage={error?.message}
                />
              );
            }}
          />
          <Controller
            control={control}
            name="product.requiresApproval"
            render={({ field }) => {
              return (
                <FormControl label="Does this product require approval?" required>
                  <RadioGroup {...field}>
                    <div>
                      <Radio value={true}>Yes</Radio>
                      <Radio value={false}>No</Radio>
                    </div>
                  </RadioGroup>
                </FormControl>
              );
            }}
          />
        </>
      )}

      {productCategory !== ProductCategory.LINK && (
        <>
          <Controller
            control={control}
            name="product.quantity"
            render={({ field: { ref: _ref, ...rest }, fieldState: { error } }) => (
              <QuantityField
                {...rest}
                error={!_.isUndefined(error)}
                errorMessage={error?.message}
              />
            )}
          />
          <Controller
            control={control}
            name="product.alertThreshold"
            render={({ field: { ref: _ref, ...rest }, fieldState: { error } }) => {
              return (
                <AlertTresholdField
                  {...rest}
                  error={!_.isUndefined(error)}
                  errorMessage={error?.message}
                />
              );
            }}
          />
          <Controller
            control={control}
            name="product.productDelegates"
            render={({ field: { value, onChange, name }, fieldState: { error } }) => {
              return (
                <DelegatesField
                  name={name}
                  value={value ?? []}
                  onChange={onChange}
                  error={!_.isUndefined(error)}
                  errorMessage={error?.message}
                />
              );
            }}
          />
          <Controller
            control={control}
            name="product.extraFields"
            render={({ field: { value, onChange, name }, fieldState: { error } }) => {
              return (
                <ExtraFields
                  name={name}
                  value={value ?? []}
                  onChange={onChange}
                  setError={setError}
                  error={!_.isUndefined(error)}
                  errorMessage={error?.message}
                />
              );
            }}
          />
        </>
      )}
      {productCategory === ProductCategory.LINK && (
        <Controller
          control={control}
          name="product.externalLink"
          render={({ field: { value, ref, ...rest }, fieldState: { error } }) => (
            <TextField
              label="What URL do you want to link to?"
              required
              helperText="This link should start with http or https."
              inputProps={{
                maxLength: 254,
                placeholder: 'https://example.com',
              }}
              inputRef={ref}
              value={value}
              className="external-link-field"
              {...rest}
              error={!_.isUndefined(error)}
              errorMessage={
                !_.isUndefined(error) && _.isNull(value) ? 'Required field.' : error?.message
              }
            />
          )}
        />
      )}
    </div>
  );
}
