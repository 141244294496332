import { BASE_URL, get, post, useFetch, usePaginatedFetch } from 'utils/sdk';

import _ from 'lodash';

import { EDateFilter } from 'pages/OrdersList/constants';

import { PurchaseStatus } from './types/order';
import { Currency } from './types/store';
import { ProductCategory } from './types/product';

import { formatGetParams } from 'utils/utilsV2';

export interface IOrderProduct {
  id: number;
  delivery_date: Date;
  date_created: Date;

  product_name: string;

  quantity: number;
  price: number;
  currency: Currency;
  status: PurchaseStatus;
  category: ProductCategory;

  store_name: string;

  guest: {
    guest_name: string;
    guest_email: string;
    guest_phone: string;
  };
  order_number: string;
  owner_purchase_uuid: string;
  guest_amount: number;
  owner_profit: number;
  amount_to_refund: number; // This is the amount that will be refunded - the price + commission

  can_approve: boolean;
  is_refunded: boolean;
  is_refundable: boolean;
  non_refundable_reason?: string;
}

export interface IOrderProductExport {
  id: number;
  delivery_date: Date;
  date_created: Date;
  product_name: string;

  quantity: number;
  price: number;
  currency: Currency;
  status: PurchaseStatus;
  category?: ProductCategory;

  store_name: string;
  guest: {
    guest_name: string;
    guest_email: string;
    guest_phone: string;
  };
  order_number: string;
  owner_purchase_uuid: string;

  guest_amount: number;
  owner_profit: number;

  is_refunded: boolean;
}

export interface IOrderProductNeedApproval {
  id: number;
  delivery_date: Date;
  product_name: string;

  quantity: number;
  price: number;
  currency: Currency;
  status: PurchaseStatus;

  store_name: string;
  guest: {
    guest_name: string;
    guest_email: string;
    guest_phone: string;
  };
  owner_purchase_uuid: string;

  guest_amount: number;
  owner_profit: number;
}
export const refundOrderProduct = ({ orderProductId }: { orderProductId: number }) =>
  post(`${BASE_URL}services/orderproduct/${orderProductId}/refund/`, {});

export const updateOrderProductQuantity = ({
  orderProductId,
  quantity,
}: {
  orderProductId: number;
  quantity: number;
}) =>
  post(`${BASE_URL}services/orderproduct/${orderProductId}/update-quantity/`, {
    count: quantity,
  });

export const useOrderProductNeedApproval = ({ ownerId }: { ownerId: number }) =>
  useFetch<Array<IOrderProductNeedApproval>>(
    `services/orderproduct/owner/${ownerId}/need-approval/`,
  );

export const useOrderProductList = ({
  storeId,
  status,
  dateFilter,
  startDate,
  endDate,
}: {
  storeId?: number;
  dateFilter?: EDateFilter;
  status?: PurchaseStatus;
  startDate?: string;
  endDate?: string;
}) => {
  let filters;

  if (!_.isUndefined(status)) {
    filters = { status };
  }
  if (!_.isUndefined(startDate) && !_.isUndefined(dateFilter)) {
    filters = { ...filters, start_date: startDate, date_filter: dateFilter };
  }
  if (!_.isUndefined(endDate) && !_.isUndefined(dateFilter)) {
    filters = { ...filters, end_date: endDate, date_filter: dateFilter };
  }

  if (!_.isUndefined(storeId)) {
    filters = { ...filters, store_id: storeId };
  }

  return usePaginatedFetch<IOrderProduct>({
    url: `services/orderproduct/recent-products/`,
    params: { limit: 10, ...filters },
  });
};

export const exportOrderProducts = ({
  storeId,
  status,
  startDate,
  endDate,
  dateFilter,
}: {
  storeId?: number;
  status?: PurchaseStatus;
  startDate?: string;
  endDate?: string;
  dateFilter?: EDateFilter;
}) => {
  let filters;

  if (!_.isUndefined(status)) {
    filters = { ...filters, status };
  }
  if (!_.isUndefined(startDate) && !_.isUndefined(dateFilter)) {
    filters = { ...filters, start_date: startDate, date_filter: dateFilter };
  }
  if (!_.isUndefined(endDate) && !_.isUndefined(dateFilter)) {
    filters = { ...filters, end_date: endDate, date_filter: dateFilter };
  }

  if (!_.isUndefined(storeId)) {
    filters = { ...filters, store_id: storeId };
  }

  return get<Array<IOrderProductExport>>(
    `${BASE_URL}services/orderproduct/export/${formatGetParams({
      ...filters,
    })}`,
  );
};
