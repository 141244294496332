import React, { useCallback } from 'react';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import { Button } from 'ui-kit';

export interface IBackToStore {
  storeId: number;
}

const BackToStore = ({ storeId }: IBackToStore) => {
  const history = useHistory();

  const goToStore = useCallback(() => {
    history.push(`/store/${storeId}`);
  }, [history, storeId]);

  return (
    <Button onClick={goToStore} startIcon={<ArrowLeftOutlined />} color="textLight">
      Back to store
    </Button>
  );
};

export default BackToStore;
