import React from 'react';
import PropTypes from 'prop-types';
import { Layout } from 'antd';
import { classNames } from 'utils';
import './Content.scss';

export default function Content({ className, children, ...rest }) {
  return (
    <Layout.Content className={classNames(className, 'StoreLayoutContent')} {...rest}>
      <div className="StoreLayoutContent_container">{children}</div>
    </Layout.Content>
  );
}

Content.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
};
