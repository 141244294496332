import React from 'react';
import { IOrder, IPurchaseInline } from 'api/order_sdk';
import { formatCurrency } from 'utils';
import dayjs from 'dayjs';

import Table from 'components/Table';
import { Button, Link, Tooltip, Typography } from 'ui-kit';
import RefundButton from 'components/RefundButton';
import { ERefundType } from 'components/RefundButton/contants';
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons';
import { PurchaseCancelReasonMap, PurchaseStatus, PurchaseStatusMap } from 'api/types/order';
import { reverse } from 'utils/urls';
import { URLS } from 'components/Routes/urls';
import { useHistory } from 'react-router-dom';
import './styles.scss';

export interface IPurchasesTable {
  order: IOrder;
  onRefundCompleted: () => void;
}

const PurchasesTable = ({ order, onRefundCompleted }: IPurchasesTable) => {
  const history = useHistory();

  return (
    <>
      {order.purchases.map((purchase: IPurchaseInline, index) => (
        <div className="DashboardSalesPurchase" key={`${order.id}-${index}`}>
          <Table
            rowKey="id"
            columns={[
              {
                title: 'Receipt',
                dataIndex: index,
                key: 'index',
                render: () => (
                  <Link href={`/dashboard/store/${order.store.id}/sales/${purchase.id}/`}>
                    Receipt details
                  </Link>
                ),
              },
              {
                title: 'Guest paid',
                dataIndex: 'guest_amount',
                key: 'guest_amount',
                render: (text, _record) => (
                  <Typography variant="body2">{formatCurrency(text, order.currency)}</Typography>
                ),
              },

              {
                title: 'Your profit',
                dataIndex: 'owner_profit',
                key: 'owner_profit',
                render: (text, _record) => (
                  <Typography variant="body2">
                    {formatCurrency(
                      purchase.status === PurchaseStatus.CANCEL ? '0.00' : text,
                      order.currency,
                    )}
                  </Typography>
                ),
              },
              {
                title: 'Fees',
                dataIndex: 'convenience_fee',
                key: 'convenience_fee',
                render: (text, _record) => (
                  <Typography variant="body2">{formatCurrency(text, order.currency)}</Typography>
                ),
              },
            ]}
            dataSource={[purchase]}
            pagination={false}
            className="DashboardSalesReceipt_subtable"
          />
          <Table
            rowKey="id"
            columns={[
              { title: 'Product Name', dataIndex: 'product_name', key: 'productName' },
              {
                title: 'Quantity',
                dataIndex: 'quantity',
                key: 'quantity',
              },
              {
                title: 'Price Per Item',
                key: 'price',
                dataIndex: 'price',
                render: (text, record) => (
                  <Typography variant="body2">{formatCurrency(text, record.currency)}</Typography>
                ),
              },
              {
                title: 'Fees',
                key: 'convenience_fee',
                dataIndex: 'convenience_fee',
                render: (text, record) => (
                  <Tooltip title="Fee may not be exact due to rounding">
                    <Typography variant="body2">{formatCurrency(text, record.currency)}</Typography>
                  </Tooltip>
                ),
              },
              {
                title: 'Date of Service',
                dataIndex: 'delivery_date',
                key: 'delivery_date',
                render: (text) => {
                  if (text) {
                    return (
                      <Typography variant="body2">{dayjs(text).format('Do MMMM YYYY')}</Typography>
                    );
                  }
                  return <Typography variant="body2">n/a</Typography>;
                },
              },

              {
                title: 'Is Refunded',
                key: 'is_refunded',
                dataIndex: 'is_refunded',
                render: (text) =>
                  text ? (
                    <CheckCircleOutlined style={{ color: 'green', fontSize: '14px' }} />
                  ) : (
                    <CloseCircleOutlined
                      style={{
                        color: 'red',
                        fontSize: '14px',
                      }}
                    />
                  ),
              },
              {
                title: 'Status',
                dataIndex: 'status',
                key: 'status',
                render: (text, order_product) => (
                  <Typography variant="body2">
                    {PurchaseStatusMap[text]}{' '}
                    {text === PurchaseStatus.CANCEL && (
                      <Tooltip
                        title={`The order is denied by ${
                          PurchaseCancelReasonMap[order_product.cancel_reason]
                        }`}
                      >
                        <ExclamationCircleOutlined />
                      </Tooltip>
                    )}
                  </Typography>
                ),
              },
              {
                title: 'Actions',
                key: 'actions',
                render: (orderProduct) => {
                  if (orderProduct.status === PurchaseStatus.HOLD) {
                    return (
                      <Tooltip
                        title={
                          orderProduct.can_approve
                            ? ''
                            : 'This product can be approved only from the merchant side'
                        }
                      >
                        <span>
                          <Button
                            variant="outlined"
                            size="small"
                            disabled={!orderProduct.can_approve}
                            onClick={() =>
                              history.push(
                                reverse({
                                  url: URLS.OWNER_MANAGE_ORDER,
                                  params: {
                                    ownerOrderUuid: orderProduct.owner_purchase_uuid,
                                  },
                                }),
                              )
                            }
                          >
                            Approve or Deny
                          </Button>
                        </span>
                      </Tooltip>
                    );
                  }
                  return (
                    <RefundButton
                      nonRefundableReason={orderProduct.non_refundable_reason}
                      disabled={!orderProduct.is_refundable}
                      title="Refund item"
                      refundType={ERefundType.ORDER_PRODUCT}
                      order={order}
                      orderProduct={orderProduct}
                      onRefundCompleted={onRefundCompleted}
                    />
                  );
                },
              },
            ]}
            dataSource={purchase.order_products}
            pagination={false}
            className="DashboardSalesProducts_subtable"
          />
        </div>
      ))}
    </>
  );
};

export default PurchasesTable;
