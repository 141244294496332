import React, { useCallback, useContext, useState } from 'react';

import { Spin } from 'ui-kit';
import { notImplementedError } from 'utils/utilsV2';

export const LoadingContext = React.createContext({
  show: notImplementedError,
  hide: notImplementedError,
});

export interface ILoadingContextProvider {
  children?: React.ReactNode;
}

export const LoadingContextProvider = ({ children }: ILoadingContextProvider) => {
  const [open, setOpen] = useState(false);

  const show = useCallback(() => {
    setOpen(true);
  }, []);

  const hide = useCallback(() => {
    setOpen(false);
  }, []);

  return (
    <LoadingContext.Provider value={{ show, hide }}>
      <Spin
        spinning={open}
        // To prevent scrolling to the top of the page when the backdrop is shown
        style={{
          position: 'fixed',
          display: 'flex',
          zIndex: 9999,
        }}
      >
        {children}
      </Spin>
    </LoadingContext.Provider>
  );
};

export const useLoadingBackdrop = () => {
  const { show, hide } = useContext(LoadingContext);

  return [show, hide];
};
