import React from 'react';
import { useParams } from 'react-router-dom';
import UnauthLayout from 'components/UnauthLayout';
import { Typography } from 'ui-kit';
type Action = 'approve' | 'deny' | 'cancel';

export default function PurchaseRequestConfirmation() {
  const { action } = useParams<{ action: Action }>();

  return (
    <UnauthLayout>
      <div className="OrderRequestConfirmation">
        <Typography variant="body1">
          We received your request to {action} the order! You should receive a confirmation email
          shortly.
        </Typography>
      </div>
    </UnauthLayout>
  );
}
