import React, { useEffect, useMemo, useState } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { keys } from 'consts';
import { useParams } from 'react-router-dom';
import { generateStripePaymentIntentClientSecret } from 'api/order_sdk';
import Layout from 'components/StoreLayout';
import { useStoreDetails } from 'api/store';
import { Spin } from 'ui-kit';
import BackToStore from 'components/Store/NewCheckout/BackToStore';
import CheckoutForm from 'components/Store/NewCheckout/CheckoutForm';
import OrderSummary from 'components/Store/NewCheckout/OrderSummary';
import { useGuestCheckoutStoreSummary } from 'api/guest_sdk';
import { message } from 'antd';
import _ from 'lodash';
import StoreLayoutHeader from 'components/StoreLayout/Header';
import Footer from 'components/Layout/Footer';
import { useAuth } from 'contexts/auth/auth';
import { Role } from 'api/types/auth';

const stripePromise = loadStripe(keys.stripe);

const CheckoutPage = () => {
  const { role } = useAuth();

  const [clientSecret, setClientSecret] = useState(null);

  const params = useParams<{ storeId?: string; orderId?: string; customerId?: string }>();

  const storeId = Number(params.storeId);
  const orderId = Number(params.orderId);
  const customerId = params.customerId;

  const {
    data: order,
    error: orderErrors,
    isLoading: isOrderLoading,
  } = useGuestCheckoutStoreSummary({
    storeId,
    orderId,
    enabled: !_.isNull(clientSecret),
  });
  const { data: storeData, isLoading: isStoreLoading } = useStoreDetails(storeId);

  const enterpriseInfo = useMemo(() => storeData?.enterpriseInfo, [storeData]);
  const isEnterprise = useMemo(() => enterpriseInfo?.isEnterprise, [enterpriseInfo]);
  const isLoading = useMemo(
    () => isStoreLoading || isOrderLoading,
    [isStoreLoading, isOrderLoading],
  );
  const companyName = useMemo(() => enterpriseInfo?.companyName, [enterpriseInfo]);
  const logo = useMemo(() => enterpriseInfo?.logo, [enterpriseInfo]);

  useEffect(() => {
    if (role === Role.HOST) {
      return;
    }

    if (orderId) {
      generateStripePaymentIntentClientSecret({
        orderId,
        customerId,
      })
        .then(({ client_secret }) => {
          setClientSecret(client_secret);
        })
        .catch((err) => message.error(err.error || err.message));
    }
  }, [orderId]);

  useEffect(() => {
    if (orderErrors) {
      message.error(orderErrors.message);
    }
  }, [orderErrors]);

  return (
    <Spin spinning={isLoading}>
      <StoreLayoutHeader
        companyName={companyName}
        logo={logo}
        isEnterprise={isEnterprise}
        isLoading={isLoading}
      />
      <Layout.Content className="StoreCheckout_layout">
        <div className="StoreCheckout_content">
          <div style={{ marginBottom: 20 }}>
            <BackToStore storeId={storeId} />
          </div>
          {clientSecret && stripePromise && (
            <Elements
              stripe={stripePromise}
              options={{
                clientSecret,
                appearance: {
                  theme: 'stripe',
                  variables: {
                    spacingUnit: '5px',
                    fontSizeSm: '0.875',
                    fontWeightBold: '600',
                    colorDanger: '#ff0000',
                  },
                },
              }}
            >
              <CheckoutForm storeId={storeId} orderId={orderId} customerId={customerId} />
            </Elements>
          )}
          {order && (
            <div style={{ marginTop: 30, marginBottom: 30 }}>
              <OrderSummary order={order} />
            </div>
          )}
        </div>
      </Layout.Content>
      <Footer isEnterprise={enterpriseInfo?.isEnabled} about={enterpriseInfo?.about} />
    </Spin>
  );
};

export default CheckoutPage;
