/* eslint-disable react/prop-types */
import React, { lazy } from 'react';
import DashboardStoreProvider from 'contexts/store/DashboardStoreProvider';
import { Switch, Route, Redirect } from 'react-router-dom';
import SalesPage from 'components/Dashboard/Sales';
import PurchaseDetails from 'pages/PurchaseDetails';

const DashboardStorePage = lazy(() =>
  import(
    /* webpackChunkName: "dashboard" */
    'pages/dashboard-store/DashboardStorePage'
  ),
);
const Signage = lazy(() =>
  import(
    /* webpackChunkName: "dashboard" */
    'pages/signage/SignagePage'
  ),
);
const SettingsStorePage = lazy(() =>
  import(
    /* webpackChunkName: "dashboard" */
    'pages/settings/Store'
  ),
);
const SettingsAccountPage = lazy(() =>
  import(
    /* webpackChunkName: "dashboard" */
    'pages/settings/Account'
  ),
);

const DashboardRoutes = ({ userId }) => {
  return (
    <Switch>
      <Route path="/dashboard/store/:storeId?">
        <DashboardStoreProvider ownerId={userId}>
          <Switch>
            <Route
              path="/dashboard/store/:storeId?/products/:category?"
              component={DashboardStorePage}
            />
            <Route
              path="/dashboard/store/:storeId?/sales/:purchaseId/"
              component={PurchaseDetails}
            />
            <Route path="/dashboard/store/:storeId?/sales/" component={SalesPage} />
            <Route path="/dashboard/store/:storeId?/signage/" component={Signage} />
            <Route path="/dashboard/store/:storeId?/payouts/">
              <Redirect to="/overview/payouts/" />
            </Route>
            <Route path="/dashboard/store/:storeId?/settings/" component={SettingsStorePage} />
            <Route path="/dashboard/store/:storeId?/account/" component={SettingsAccountPage} />
            <Route path="/dashboard/store/:storeId?" component={DashboardStorePage} />
          </Switch>
        </DashboardStoreProvider>
      </Route>
      <Route>
        <Redirect to="/dashboard/store/" />
      </Route>
    </Switch>
  );
};

export default DashboardRoutes;
