import React, { useState, useRef, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Row, Col, Alert, Divider, message } from 'antd';
import { generateToken } from 'api/owner_sdk';
import UnauthLayout from 'components/UnauthLayout';
import ForgotPasswordForm from './ForgotPasswordForm';
import Button from 'ui-kit/Button/Button';
import { Typography } from 'ui-kit';

const ForgotPasswordPage = () => {
  const alertRef = useRef();
  const location = useLocation();
  const email = location?.state?.email ?? '';
  const [error, setError] = useState('');
  const [successEmail, setSuccessEmail] = useState('');

  const handleSubmit = ({ email }) => {
    return generateToken({ email })
      .then(() => {
        setSuccessEmail(email);
      })
      .catch((error) => {
        message.error(error.message);
        setError(error.message);
      });
  };

  useEffect(() => {
    if (error) {
      alertRef.current.focus();
    }
  }, [error]);

  return (
    <UnauthLayout>
      <Row gutter={[0, 16]}>
        <Col xs={!error ? 0 : 24} ref={alertRef}>
          <Alert showIcon type="error" message={error} />
        </Col>
        {!successEmail ? (
          <>
            <Col xs={24}>
              <div>
                <Typography variant="h6">Forgot your password?</Typography>
                <Divider />
              </div>
              <Typography variant="body2">
                Enter your email below to receive a link to reset your password.
              </Typography>
            </Col>

            <ForgotPasswordForm initialValues={{ email }} onSubmit={handleSubmit} />
          </>
        ) : (
          <Row gutter={[0, 8]}>
            <div>
              <Typography variant="h6">Reset link sent!</Typography>
              <Divider />
            </div>
            <p>
              Password reset instructions have been sent to <b>{successEmail}</b>! Please check your
              inbox for a reset link.
            </p>
            <Col xs={24}>
              <Button to="/login" variant="contained" fullWidth>
                Sign in
              </Button>
            </Col>
          </Row>
        )}
      </Row>
    </UnauthLayout>
  );
};

export default ForgotPasswordPage;
