import { NotifyBy } from './owner';

export enum Role {
  NO_AUTH = 'NO_AUTH',
  GUEST_ANONYMOUS = 'GUEST_ANONYMOUS',
  HOST = 'HOST',
  ADMIN = 'ADMIN',
}

export enum ActorType {
  AD = 'AD',
  OW = 'OW',
  GU = 'GU',
}

export interface Admin {
  id: number;
  dateCreated: string;
  dateModified: string;
  isActive: boolean;
  deviceId: string | null;
  registrationId: string | null;
  isSuper: boolean;
  actor: number;
}

export interface GuestRegistration {
  id: number;
  email: string;
  username: string;
  password: string;
  firstName: string;
  lastName: string;
  isAnonymous: boolean;
  dateCreated: string;
  dateModified: string;
  image: string | null;
  isActive: boolean;
  deviceId: string | null;
  registrationId: string | null;
  notifyBy: NotifyBy;
  isComplete: boolean;
  actor: number;
}

export interface ActorSnippet {
  id: number;
  prefix: string | null;
  firstName: string;
  lastName: string;
  suffix: string | null;
  type: ActorType;
}

interface User {
  id: number;
  password: string;
  lastLogin: string | null;
  isSuperuser: boolean;
  email: string;
  isStaff: boolean;
  isActive: boolean;
  dateJoined: string;
  passwordExpiration: string | null;
  groups: number[];
  userPermissions: number[];
}

interface UserSocialAuth {
  provider: string;
  id: number;
}

export interface UserSnippet extends Pick<User, 'id' | 'email' | 'lastLogin' | 'isActive'> {
  socialAuths: UserSocialAuth[];
  hasSetPassword: boolean;
}

export interface UserDisabledSnippet {
  id: number;
  dateCreated: string;
  reason: string;
}

enum ContactType {
  HOME = 'HOME',
  WORK = 'WORK',
  BILL = 'BILL',
  OTH = 'OTH',
}

interface ContactInfo {
  id: number;
  dateCreated: string;
  street1: string | null;
  street2: string | null;
  city: string | null;
  state: string | null;
  postalCode: string | null;
  phone: string | null;
  mobile: string | null;
  fax: string | null;
  email: string | null;
  name: string | null;
  type: ContactType;
  isPreferred: boolean;
  actor: number;
}

export interface ContactInfoSnippet extends Omit<ContactInfo, 'dateCreateed' | 'actor'> {}
