import React, { useMemo } from 'react';
import { Pagination } from 'antd';
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';

import { IOrderProduct } from 'api/order_product_sdk';

import Table from 'components/Table';
import Stack from 'ui-kit/Stack';
import { Button, Tooltip, Typography } from 'ui-kit';
import { reverse } from 'utils/urls';
import { URLS } from 'components/Routes/urls';

import { formatCurrency } from 'utils';
import { ProductCategoryMap } from 'api/types/product';
import { PurchaseStatus, PurchaseStatusMap } from 'api/types/order';
import { ERefundType } from '../../RefundButton/contants';
import RefundButton from '../../RefundButton';

import './styles.scss';

export interface IProductsTable {
  products: Array<IOrderProduct>;
  currentPage: number;
  count: number;
  loading: boolean;
  setPage: (page: number) => void;
  onRefundCompleted: () => void;
}

const OrderProductsTable = ({
  products,
  currentPage,
  count,
  loading,
  setPage,
  onRefundCompleted,
}: IProductsTable) => {
  const getActionColumn = ({ onRefundCompleted }: { onRefundCompleted: () => void }) => ({
    title: 'Actions',
    key: 'actions',
    render: (text, orderProduct) => {
      if (orderProduct.status === PurchaseStatus.HOLD) {
        return (
          <Tooltip
            title={
              orderProduct.can_approve
                ? ''
                : 'This product can be approved only from the merchant side'
            }
          >
            <span>
              <Button
                variant="outlined"
                size="small"
                disabled={!orderProduct.can_approve}
                href={reverse({
                  url: URLS.OWNER_MANAGE_ORDER,
                  params: {
                    ownerOrderUuid: orderProduct.owner_purchase_uuid,
                  },
                })}
              >
                Approve or Deny
              </Button>
            </span>
          </Tooltip>
        );
      }
      return (
        <RefundButton
          orderProduct={orderProduct}
          nonRefundableReason={orderProduct.non_refundable_reason}
          disabled={!orderProduct.is_refundable}
          refundType={ERefundType.ORDER_PRODUCT}
          onRefundCompleted={onRefundCompleted}
          title="Refund item"
        />
      );
    },
  });

  const columns = useMemo(() => {
    return [
      {
        title: 'Date of Service',
        dataIndex: 'delivery_date',
        key: 'delivery_date',
        render: (text) => (
          <Typography variant="body2" className="store-name-cell">
            {dayjs(text).format('Do MMM YYYY')}
          </Typography>
        ),
      },
      {
        title: 'Product',
        dataIndex: 'product_name',
        key: 'product_name',
        render: (text) => (
          <Typography variant="body2" className="store-name-cell">
            {text}
          </Typography>
        ),
      },
      {
        title: 'Quantity',
        dataIndex: 'quantity',
        key: 'quantity',
        render: (text) => (
          <Typography variant="body2" className="store-name-cell">
            {text}
          </Typography>
        ),
      },
      {
        title: 'Item price',
        dataIndex: 'price',
        key: 'price',
        render: (text, record) => (
          <Typography variant="body2" className="store-name-cell">
            {formatCurrency(text, record.currency)}
          </Typography>
        ),
      },
      {
        title: 'Product category',
        dataIndex: 'category',
        key: 'category',
        render: (text) => (
          <Typography variant="body2" className="store-name-cell">
            {ProductCategoryMap[text]}
          </Typography>
        ),
      },
      {
        title: 'Store',
        dataIndex: 'store_name',
        key: 'store_name',
        render: (text) => (
          <Tooltip title={text}>
            <Typography variant="body2" rows={2} className="store-name-cell">
              {text}
            </Typography>
          </Tooltip>
        ),
      },
      {
        title: 'Guest',
        dataIndex: 'guest',
        key: 'guest',
        render: (text, record) => (
          <div className="store-name-cell">
            <Typography variant="body2" rows={2}>
              <strong>Name: </strong>
              {record.guest?.guest_name}
            </Typography>
            <Typography variant="body2" rows={2}>
              <strong>Email: </strong>
              {record.guest?.guest_email}
            </Typography>
            <Typography variant="body2" rows={2}>
              <strong>Phone: </strong>
              {record.guest?.guest_phone}
            </Typography>
          </div>
        ),
      },
      {
        title: 'Sale Number',
        dataIndex: 'order_number',
        key: 'order_number',
        render: (text) => (
          <Typography variant="body2" className="store-name-cell">
            {text}
          </Typography>
        ),
      },
      {
        title: 'Date of Purchase',
        dataIndex: 'date_created',
        key: 'date_created',
        render: (text) => (
          <Typography variant="body2" className="store-name-cell">
            {dayjs(text).format('Do MMM YYYY')}
          </Typography>
        ),
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        render: (text) => (
          <Typography variant="body2" className="store-name-cell">
            {PurchaseStatusMap[text]}
          </Typography>
        ),
      },

      {
        title: 'Guest Paid',
        dataIndex: 'guest_amount',
        key: 'guest_amount',
        render: (text, record) => (
          <Typography variant="body2" rows={1}>
            {formatCurrency(text, record.currency)}
          </Typography>
        ),
      },
      {
        title: 'Your profit',
        dataIndex: 'owner_profit',
        key: 'owner_profit',
        render: (text, record) => (
          <Typography variant="body2" rows={1}>
            {formatCurrency(text, record.currency)}
          </Typography>
        ),
      },
      {
        title: 'Is refunded',
        dataIndex: 'is_refunded',
        key: 'is_refunded',
        render: (text) =>
          text ? (
            <CheckCircleOutlined style={{ color: 'green', fontSize: '14px' }} />
          ) : (
            <CloseCircleOutlined
              style={{
                color: 'red',
                fontSize: '14px',
              }}
            />
          ),
      },

      getActionColumn({ onRefundCompleted }),
    ];
  }, [count]);

  return (
    <Stack flexDirection="column" alignItems="center">
      <Table
        loading={loading}
        className="recent-products-table"
        dataSource={products}
        scroll={{ x: 500 }}
        rowKey="id"
        pagination={false}
        columns={columns}
      />
      <Pagination onChange={setPage} current={currentPage} total={count} showSizeChanger={false} />
    </Stack>
  );
};

export default OrderProductsTable;
