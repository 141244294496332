import React, { useCallback, useState } from 'react';
import { Plus as PlusIcon, Minus as MinusIcon } from 'react-feather';
import { Typography, message } from 'antd';
import IconButton from 'ui-kit/IconButton';
import { IGuestCartDetail } from 'api/guest_sdk';
import { updateOrderProductQuantity } from 'api/order_product_sdk';
import ConfirmModalDeductOrderProduct from '../ConfirmModalDeductOrderProduct';

export interface ICountControls {
  orderProduct: IGuestCartDetail['order_products'][number];
  onDeduct: () => void;
  onAddQuantity: () => void;
}

const CountControls = ({ onDeduct, onAddQuantity, orderProduct }: ICountControls) => {
  const [isOpenConfirmModal, setIsOpenConfirmModal] = useState(false);

  const handleAddItem = useCallback(
    () =>
      updateOrderProductQuantity({
        orderProductId: orderProduct.id,
        quantity: orderProduct.quantity + 1,
      })
        .then(() => onAddQuantity())
        .catch((error) => {
          message.error(error?.message);
        }),
    [orderProduct],
  );

  const onDeductItem = useCallback(
    (quantity) => {
      updateOrderProductQuantity({
        orderProductId: orderProduct.id,
        quantity,
      })
        .then(() => onDeduct())
        .catch((error) => {
          message.error(error?.message);
        });
    },
    [orderProduct],
  );

  const handleDeductItem = useCallback(() => {
    if (orderProduct.quantity === 1) {
      setIsOpenConfirmModal(true);
    } else {
      onDeductItem(orderProduct.quantity - 1);
    }
  }, [orderProduct, onDeductItem]);

  const handleConfirmRemoveProduct = useCallback(() => {
    onDeductItem(0);
    setIsOpenConfirmModal(false);
  }, [onDeductItem]);

  return (
    <>
      <div className="StoreCheckout_orderCellQuantity">
        <IconButton
          className="StoreCheckout_orderCellQuantityButton"
          color="textDark"
          variant="outlined"
          size="small"
          onClick={handleDeductItem}
        >
          <MinusIcon className="StoreCheckout_orderCellQuantityButtonIcon" />
        </IconButton>
        <Typography.Text className="StoreCheckout_orderCellQuantityValue" strong>
          {orderProduct.quantity}
        </Typography.Text>
        <IconButton
          className="StoreCheckout_orderCellQuantityButton"
          variant="outlined"
          color="textDark"
          size="small"
          onClick={handleAddItem}
        >
          <PlusIcon className="StoreCheckout_orderCellQuantityButtonIcon" />
        </IconButton>
      </div>
      <ConfirmModalDeductOrderProduct
        isOpen={isOpenConfirmModal}
        onConfirm={handleConfirmRemoveProduct}
        onCancel={() => setIsOpenConfirmModal(false)}
      />
    </>
  );
};

export default CountControls;
