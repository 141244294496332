import { BASE_URL, get, post, usePaginatedFetch } from 'utils/sdk';
import _ from 'lodash';

import { EPurchaseCancelReason, PurchaseStatus } from './types/order';
import { Currency } from './types/store';
import { formatGetParams } from 'utils/utilsV2';
import { ERefundType } from 'components/RefundButton/contants';
import { EDateFilter } from 'pages/OrdersList/constants';

export interface IOrderProductInline {
  id: number;
  product_name: string;
  delivery_date: Date;

  quantity: number;
  price: number;
  currency: Currency;
  status: PurchaseStatus;

  owner_purchase_uuid: string;

  cancel_reason?: EPurchaseCancelReason;
  convenience_fee: number;

  amount_to_refund: number; // This is the amount that will be refunded - the price + commission

  can_approve: boolean;
  is_refunded: boolean;
  is_refundable: boolean;
  non_refundable_reason?: string;
}

export interface IPurchaseInline {
  id: number;
  status: PurchaseStatus;

  owner_purchase_uuid: string;

  guest_amount: number;
  owner_profit: number;
  convenience_fee: number;

  order_products: Array<IOrderProductInline>;
}

export interface IOrder {
  id: number;
  order_number: string;
  date_created: string;
  guest: {
    guest_name: string;
    guest_email: string;
    guest_phone: string;
  };

  store: {
    id: number;
    store_name: string;
  };

  grand_total: number;
  currency: Currency;

  is_refundable: boolean;
  non_refundable_reason: string;

  purchases: Array<IPurchaseInline>;
}

export interface IOrderExport {
  id: number;
  order_number: string;
  date_created: string;

  guest: {
    guest_name: string;
    guest_email: string;
    guest_phone: string;
  };

  store: {
    id: number;
    store_name: string;
  };

  currency: Currency;
  grand_total: number;
}

export interface IOrderOrderProductExport {
  id: number;

  order_number: string;
  product_name: string;
  store_name: string;

  date_created: string;
  delivery_date: string;

  guest: {
    guest_name: string;
    guest_email: string;
    guest_phone: string;
  };

  guest_amount: number;
  owner_profit: number;
}

export const useOrders = ({
  storeId,
  status,
  dateFilter,
  startDate,
  endDate,
}: {
  storeId?: number;
  dateFilter?: EDateFilter;
  status?: PurchaseStatus;
  startDate?: string;
  endDate?: string;
}) => {
  let filters;

  if (!_.isUndefined(status)) {
    filters = { status };
  }
  if (!_.isUndefined(startDate) && !_.isUndefined(dateFilter)) {
    filters = { ...filters, start_date: startDate, date_filter: dateFilter };
  }
  if (!_.isUndefined(endDate) && !_.isUndefined(dateFilter)) {
    filters = { ...filters, end_date: endDate, date_filter: dateFilter };
  }

  if (!_.isUndefined(storeId)) {
    filters = { ...filters, store_id: storeId };
  }

  return usePaginatedFetch<IOrder>({
    url: `services/order/list/`,
    params: { limit: 10, ...filters },
  });
};

export const exportOrders = ({
  storeId,
  status,
  startDate,
  endDate,
  dateFilter,
}: {
  storeId?: number;
  status?: PurchaseStatus;
  startDate?: string;
  endDate?: string;
  dateFilter?: EDateFilter;
}) => {
  let filters;

  if (!_.isUndefined(status)) {
    filters = { status };
  }
  if (!_.isUndefined(startDate) && !_.isUndefined(dateFilter)) {
    filters = { ...filters, start_date: startDate, date_filter: dateFilter };
  }
  if (!_.isUndefined(endDate) && !_.isUndefined(dateFilter)) {
    filters = { ...filters, end_date: endDate, date_filter: dateFilter };
  }

  if (!_.isUndefined(storeId)) {
    filters = { ...filters, store_id: storeId };
  }

  return get<Array<IOrderExport>>(
    `${BASE_URL}services/order/export-orders/${formatGetParams({
      ...filters,
    })}`,
  );
};

export const exportOrderOrderProducts = ({
  storeId,
  status,
  startDate,
  endDate,
  dateFilter,
}: {
  storeId?: number;
  status?: PurchaseStatus;
  startDate?: string;
  endDate?: string;
  dateFilter?: EDateFilter;
}) => {
  let filters;

  if (!_.isUndefined(status)) {
    filters = { status };
  }
  if (!_.isUndefined(startDate) && !_.isUndefined(dateFilter)) {
    filters = { ...filters, start_date: startDate, date_filter: dateFilter };
  }
  if (!_.isUndefined(endDate) && !_.isUndefined(dateFilter)) {
    filters = { ...filters, end_date: endDate, date_filter: dateFilter };
  }

  if (!_.isUndefined(storeId)) {
    filters = { ...filters, store_id: storeId };
  }

  return get<Array<IOrderOrderProductExport>>(
    `${BASE_URL}services/order/export-orderproducts/${formatGetParams({
      ...filters,
    })}`,
  );
};

export const refundOrder = ({ orderId }: { orderId: number }) =>
  post(`${BASE_URL}services/order/${orderId}/refund/`, {});

export const getRefundStatus = ({
  refundType,
  refundRequestId,
}: {
  refundType: ERefundType;
  refundRequestId: number;
}) => {
  if (refundType === ERefundType.ORDER) {
    return get<{ is_refunded: boolean }>(
      `${BASE_URL}services/order/${refundRequestId}/is-refunded/`,
    );
  }

  return get<{ is_refunded: boolean }>(
    `${BASE_URL}services/orderproduct/${refundRequestId}/is-refunded/`,
  );
};

export const createCustomer = () =>
  post<{ id: string }>(`${BASE_URL}services/order/create-customer/`, {});

export const updateCustomer = ({
  orderId,
  customerId,
  firstName,
  lastName,
  phone,
}: {
  orderId: number;
  customerId: string;
  firstName: string;
  lastName: string;
  phone: string;
}) =>
  post(`${BASE_URL}services/order/${orderId}/update-customer/`, {
    customer_id: customerId,
    first_name: firstName,
    last_name: lastName,
    phone,
  });

export const generateStripePaymentIntentClientSecret = ({
  orderId,
  customerId,
}: {
  orderId: number;
  customerId: string;
}) =>
  post<{ client_secret: string }>(`${BASE_URL}services/order/create-payment-intent/`, {
    order_id: orderId,
    customer_id: customerId,
  });

export const retryPayment = ({ orderId }: { orderId: number }) =>
  post(`${BASE_URL}services/order/${orderId}/retry-payment/`, {});
