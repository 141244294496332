import { IGuestCartDetail, IGuestCheckoutStoreSummary } from 'api/guest_sdk';
import _ from 'lodash';
import React, { useMemo } from 'react';
import { Card, Typography } from 'ui-kit';
import Stack from 'ui-kit/Stack';
import { formatCurrency } from 'utils/utils';

export interface IOrderSummary {
  order: IGuestCartDetail | IGuestCheckoutStoreSummary;
}

const Divider = () => (
  <div
    style={{
      flex: '1 1 auto',
      borderBottom: '1px dashed #e0e0e0',
      height: '1px',
      margin: '0 8px',
    }}
  />
);

const OrderSummary = ({ order }: IOrderSummary) => {
  const productCount = useMemo(() => {
    if (_.has(order, 'order_products')) {
      return _.get(order, 'order_products', []).length;
    } else if (_.has(order, 'order_product_quantity')) {
      return _.get(order, 'order_product_quantity', 0);
    }

    return 0;
  }, [order]);

  return (
    <Card style={{ padding: '20px' }}>
      <Typography variant="h6" style={{ marginBottom: '8px', fontWeight: 'bold' }}>
        Order Summary
      </Typography>
      <Stack flexDirection="column" gap="8px">
        <Stack justifyContent="space-between" alignItems="flex-end" style={{ width: '100%' }}>
          <Typography variant="body1">Items ({productCount})</Typography>
          <Divider />
          <Typography variant="body1">
            {formatCurrency(Number(order?.total_amount || 0), order.currency)}
          </Typography>
        </Stack>
        <Stack justifyContent="space-between" alignItems="flex-end" style={{ width: '100%' }}>
          <Typography variant="body1">Convenience Fee</Typography>
          <Divider />
          <Typography variant="body1">
            {formatCurrency(Number(order?.total_convenience_fees || 0), order.currency)}
          </Typography>
        </Stack>
        {/* We don't have taxes in the system, but we want to show that they are zero */}
        <Stack justifyContent="space-between" alignItems="flex-end" style={{ width: '100%' }}>
          <Typography variant="body1">Tax</Typography>
          <Divider />
          <Typography variant="body1">{formatCurrency(0, order.currency)}</Typography>
        </Stack>
        <Stack
          justifyContent="space-between"
          alignItems="flex-end"
          style={{ width: '100%', marginTop: '8px' }}
        >
          <Typography variant="body1" color="secondary" style={{ fontWeight: 'bold' }}>
            Grand total
          </Typography>
          <Divider />
          <Typography variant="body1" color="secondary" style={{ fontWeight: 'bold' }}>
            {' '}
            {formatCurrency(Number(order?.guest_total || 0), order.currency)}
          </Typography>
        </Stack>
      </Stack>
    </Card>
  );
};

export default OrderSummary;
