import { post, get } from 'api/api';
import useSWR from 'swr';
import { Product } from './types/product';
import { LocalProduct } from './types/localproduct';

export const productF2bDict = {
  name: 'product_name',
  zipCode: 'postal_code',
  productDelegates: 'product_delegates',
};

export const getProductsList = ({ storeId, categories, ...rest }) =>
  post<Product[]>(
    '/services/product/search/',
    {
      storeId,
      categories,
      ...rest,
    },
    {
      f2bDict: productF2bDict,
    },
  );

export const getLocalProductsList = ({ postalCode }) =>
  get<LocalProduct[]>(
    `/services/localproduct/lookup/`,
    {},
    {
      f2bDict: productF2bDict,
      params: {
        postal_code: postalCode,
      },
    },
  );

export const useProductsList = ({ storeId, categories = '', ...rest }) => {
  const key = `/services/product/search/${storeId}`;
  const { data, error, mutate } = useSWR(categories ? [key, categories] : key, () =>
    getProductsList({ storeId, categories, ...rest }),
  );
  return {
    data: data?.data,
    isLoading: !error && !data,
    isError: error,
    mutate,
  };
};
