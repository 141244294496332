import IntegrationPage from 'pages/IntegrationPage';

import React, { lazy } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { URLS } from './urls';

const Partners = lazy(() =>
  import(
    /* webpackChunkName: "overview" */
    'components/Partners/Partners'
  ),
);
const PartnerLanding = lazy(() =>
  import(
    /* webpackChunkName: "overview" */
    'components/Partners/PartnerLanding/PartnerLanding'
  ),
);

const PartnerRoutes = () => {
  return (
    <Switch>
      <Route exact path="/overview/partners/" component={Partners} />
      <Route path="/overview/partners/landing/:company?" component={PartnerLanding} />
      <Route path={URLS.INTEGRATION} component={IntegrationPage} />
      <Route>
        <Redirect to="/overview/partners/" />
      </Route>
    </Switch>
  );
};

export default PartnerRoutes;
