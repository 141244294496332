import { BASE_URL, post } from 'utils/sdk';
import { ProductCategory } from './types/product';

interface IProductCreateProps {
  storeId: number;
  massCreate: boolean;
  name: string;
  category: ProductCategory;
  description: string;
  price: number;
  isGuestPrice: boolean;
  quantity: number;
  hasQuantity: boolean;
  preorderBy: number;
  image: string;
  alertThreshold?: number;
  requiresApproval?: boolean;
  subcategory?: string;
  externalLink?: string;
  instructions?: string;
  productDelegates?: Array<{
    id: number;
    email: string;
    phoneNumber: string;
    isSalesSmsEnabled: boolean;
    isRequestSmsEnabled: boolean;
    isShowPrice: boolean;
  }>;
  extraFields?: Array<{
    label: string;
  }>;
}

interface IProductUpdateProps {
  productId: number;
  massCreate: boolean;
  massUpdate: boolean;
  name: string;
  category: ProductCategory;
  description: string;
  price: number;
  isGuestPrice: boolean;
  quantity: number;
  hasQuantity: boolean;
  preorderBy: number;
  image: string | undefined;
  alertThreshold?: number;
  requiresApproval?: boolean;
  subcategory?: string;
  externalLink?: string;
  instructions?: string;
  productDelegates?: Array<{
    id: number;
    email: string;
    phoneNumber: string;
    isSalesSmsEnabled: boolean;
    isRequestSmsEnabled: boolean;
    isShowPrice: boolean;
  }>;
  extraFields?: Array<{
    label: string;
  }>;
}

export const productCreate = ({
  storeId,
  massCreate,
  name,
  category,
  description,
  price,
  isGuestPrice,
  quantity,
  hasQuantity,
  preorderBy,
  image,
  alertThreshold,
  requiresApproval,
  subcategory,
  externalLink,
  instructions,
  productDelegates,
  extraFields,
}: IProductCreateProps) => {
  const formattedProductDelegates = productDelegates?.map(
    ({ id, email, phoneNumber, isSalesSmsEnabled, isRequestSmsEnabled, isShowPrice }) => ({
      id,
      email,
      phone_number: phoneNumber,
      is_sales_sms_enabled: isSalesSmsEnabled,
      is_request_sms_enabled: isRequestSmsEnabled,
      is_show_price: isShowPrice,
    }),
  );

  return post<{ product_group_id?: number }>(`${BASE_URL}services/product/create/`, {
    store_id: storeId,
    mass_create: massCreate,
    product_name: name,
    category,
    description,
    price,
    is_guest_price: isGuestPrice,
    quantity,
    has_quantity: hasQuantity,
    preorder_by: preorderBy,
    image,
    alert_threshold: alertThreshold,
    requires_approval: requiresApproval,
    subcategory,
    external_link: externalLink,
    instructions,
    product_delegates: formattedProductDelegates,
    extra_fields: extraFields,
  });
};

export const productUpdate = ({
  productId,
  massCreate,
  massUpdate,
  name,
  category,
  description,
  price,
  isGuestPrice,
  quantity,
  hasQuantity,
  preorderBy,
  image,
  alertThreshold,
  requiresApproval,
  subcategory,
  externalLink,
  instructions,
  productDelegates,
  extraFields,
}: IProductUpdateProps) => {
  const formattedProductDelegates = productDelegates?.map(
    ({ id, email, phoneNumber, isSalesSmsEnabled, isRequestSmsEnabled, isShowPrice }) => ({
      id,
      email,
      phone_number: phoneNumber,
      is_sales_sms_enabled: isSalesSmsEnabled,
      is_request_sms_enabled: isRequestSmsEnabled,
      is_show_price: isShowPrice,
    }),
  );

  return post<{ product_group_id?: number }>(`${BASE_URL}services/product/${productId}/update/`, {
    product_name: name,
    category,
    description,
    price,
    is_guest_price: isGuestPrice,
    quantity,
    has_quantity: hasQuantity,
    preorder_by: preorderBy,
    image,
    alert_threshold: alertThreshold,
    requires_approval: requiresApproval,
    subcategory,
    external_link: externalLink,
    instructions,
    product_delegates: formattedProductDelegates,
    extra_fields: extraFields,
    mass_create: massCreate,
    mass_update: massUpdate,
  });
};

export const productDelete = ({ productId }: { productId: number }) => {
  return post(`${BASE_URL}services/product/${productId}/delete/`);
};

export const productActiveUpdate = ({
  productId,
  isActive,
}: {
  productId: number;
  isActive: boolean;
}) => {
  return post<{ is_active: boolean }>(`${BASE_URL}services/product/${productId}/update-active/`, {
    is_active: isActive,
  });
};

export const massProductDelete = ({ productId }: { productId: number }) =>
  post(`${BASE_URL}services/product/${productId}/mass-delete/`);

export const createProductGroupAndPropagate = ({ productId }: { productId: number }) =>
  post(`${BASE_URL}services/product/${productId}/propagate/`, {});
