import React from 'react';
import { Typography } from 'antd';
import Layout from 'components/StoreLayout';
import { Button, Link } from 'ui-kit';

import './styles.scss';

export interface ISuccessPayment {
  storeId: number;
}

const SuccessPayment = ({ storeId }: ISuccessPayment) => (
  <div className="StoreCheckoutSuccess">
    <Layout className="">
      <Layout.Content className="StoreCheckoutSuccess_layout">
        <div className="StoreCheckoutSuccess_content">
          <Typography.Title className="StoreCheckoutSuccess_title" level={1}>
            Thank you!
          </Typography.Title>
          <Typography.Paragraph className="StoreCheckoutSuccess_text">
            A message with instructions has been sent to your email. If you have not received it,
            please check your spam folder and email us if you need assistance:{' '}
            <Link target="_blank" href="mailto:support@thehost.co">
              support@thehost.co
            </Link>
          </Typography.Paragraph>
          <Button variant="contained" to={`/store/${storeId}/`}>
            Back to store
          </Button>
        </div>
      </Layout.Content>
    </Layout>
  </div>
);

export default SuccessPayment;
