import React from 'react';

import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Tooltip, Typography } from 'ui-kit';

const OwnerProfitTooltip = () => (
  <Tooltip
    title={
      <Typography variant="body2" style={{ color: 'white' }}>
        7% commission + aprox bank merchant{' '}
        <a
          href="https://stripe.com/pricing"
          target="_blank"
          rel="noreferrer"
          style={{
            textDecoration: 'underline',
            color: 'white',
          }}
        >
          fees.
        </a>{' '}
        <a
          href={`/pricing/`}
          target="_blank"
          rel="noreferrer"
          style={{
            textDecoration: 'underline',
            color: 'white',
          }}
        >
          Upgrade to Pro
        </a>{' '}
        to have these fees removed.
      </Typography>
    }
    trigger="click"
  >
    <ExclamationCircleOutlined />
  </Tooltip>
);

export default OwnerProfitTooltip;
